import {
  CaretDownOutlined,
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  MailOutlined,
  PhoneOutlined,
  YoutubeFilled,
} from "@ant-design/icons";
import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";

import React, { useEffect, useRef } from "react";
import Button from "../../components/Button/index";
import Banner from "../../components/layouts/HomeLayout/Banner";
import Footer from "../../components/layouts/HomeLayout/Footer";
import ContactBanner from "../../assets/img/contact-banner.webp";
import { format, validate } from "rut.js";
import { sendZohoPayload } from "../../requests/contact";
import { useLocation } from "react-router-dom";
import GoToTop from "../../components/GoToTop";
import "./index.less";
import { useMediaPredicate } from "react-media-hook";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import {
  getContactHeaderContent,
  getContactSiderContent,
} from "../../store/strapi/gamaleasing";
import { getGamaLeasingContactContent } from "../../store/strapi/gamaleasing/thunks";
function Contact() {
  const [form] = Form.useForm();
  const search = useLocation().search;
  const utmMedium = new URLSearchParams(search).get("utm_medium") || null;
  const utmSource = new URLSearchParams(search).get("utm_source") || null;
  const utmCampaign = new URLSearchParams(search).get("utm_campaign") || null;
  const reRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGamaLeasingContactContent());
  }, []);

  const contactSider = useSelector(getContactSiderContent);

  const contactHeader = useSelector(getContactHeaderContent);

  useEffect(() => {
    form.setFieldsValue({
      "vehicle-number": 1,
      phonePrefix: "56",
    });
  }, []);

  const onFinish = async (values) => {
    const token = await reRef.current.executeAsync();
    reRef.current.reset();

    try {
      values.phonePrefix = +values.phonePrefix;
      values.phoneNumber = +values.phoneNumber;
      const payload = { ...values, utmMedium, utmSource, utmCampaign };
      const { data } = await sendZohoPayload({ ...payload, reCaptcha: token });

      if (data.success)
        return Modal.success({
          content: "Datos enviados con éxito",
          onOk() {
            window.location.replace("/");
          },
        });
    } catch (error) {
      if (error)
        return Modal.error({
          content: "Ha ocurrido un error al enviar los datos",
        });
    }
  };

  //Temporalmente deshabilitado
  // useEffect(() => {
  //   if (utmMedium) document.cookie = `utm_medium=${utmMedium}`;
  //   if (utmSource) document.cookie = `utm_source=${utmSource}`;
  //   if (utmCampaign) document.cookie = `utm_campaign=${utmCampaign}`;
  // }, []);

  const { Option } = Select;
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");

  const { Text } = Typography;
  return (
    <div className="required-none">
      <GoToTop />
      <Banner
        urlImage={
          contactHeader?.length === 0 || contactHeader === null
            ? ContactBanner
            : contactHeader?.bannerImage[0]?.url
        }
        height="60"
        fontSize="24px"
        title={
          contactHeader?.length === 0 || contactHeader === null
            ? "Nuevo Arriendo"
            : contactHeader?.title?.Title
        }
        subtitle={
          contactHeader?.length === 0 || contactHeader === null
            ? "Llena nuestro formulario y te contactaremos a la brevedad"
            : contactHeader?.title?.Detail
        }
        titleAlign="left"
      />

      <Row>
        {biggerThan900 ? (
          <>
            <Col span={7}>
              <Row
                style={{
                  backgroundColor: "#2D2D2D",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "left",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <Text
                    style={{
                      color: "white",
                      marginBottom: "10px",
                      marginTop: " 40px",
                    }}
                  >
                    {contactSider.length === 0 || contactSider === null
                      ? "Contacto"
                      : contactSider?.[0]?.Title}
                  </Text>
                  <Row
                    justify="start"
                    style={{ color: "white", marginBottom: "5px" }}
                  >
                    <Col>
                      <PhoneOutlined
                        style={{ color: "#FF3200", marginRight: "15px" }}
                      />
                    </Col>
                    <Col style={{ color: "white" }}>+56 93 918 5879</Col>
                  </Row>
                  <Row>
                    <Col>
                      <MailOutlined
                        style={{ color: "#FF3200", marginRight: "15px" }}
                      />
                    </Col>
                    <Col style={{ color: "white" }}>
                      {contactSider.length === 0 || contactSider === null
                        ? "contacto@gamaleasing.cl"
                        : contactSider?.[0]?.Card?.[1]?.Title}
                    </Col>
                  </Row>
                  <Text
                    style={{
                      color: "white",
                      marginTop: "30px",
                      marginBottom: "15px",
                    }}
                  >
                    {contactSider.length === 0 || contactSider === null
                      ? "Siguenos"
                      : contactSider?.[1]?.Title}
                  </Text>
                  <Row justify="space-evenly" style={{ width: "200px" }}>
                    <Col>
                      <a
                        href={
                          contactSider?.length === 0 || contactSider === null
                            ? "https://www.facebook.com/gamaleasing2013"
                            : contactSider[0]?.Paragraph
                        }
                      >
                        <FacebookFilled
                          style={{
                            color: "white",
                            backgroundColor: "rgb(255, 255, 255, 0.1)",
                            padding: "10px 10px",
                            borderRadius: "20px",
                            fontSize: "20px",
                          }}
                        />
                      </a>
                    </Col>
                    <Col>
                      <a
                        href={
                          contactSider?.length || contactSider === null
                            ? "https://www.instagram.com/gamaleasing/"
                            : contactSider[1]?.Paragraph
                        }
                      >
                        <InstagramFilled
                          style={{
                            color: "white",
                            backgroundColor: "rgb(255, 255, 255, 0.1)",
                            padding: "10px 10px",
                            borderRadius: "20px",
                            fontSize: "20px",
                          }}
                        />
                      </a>
                    </Col>
                    <Col>
                      <a
                        href={
                          contactSider?.length || contactSider === null
                            ? "https://www.linkedin.com/company/gamaleasing/"
                            : contactSider[2]?.Paragraph
                        }
                      >
                        <LinkedinFilled
                          style={{
                            color: "white",
                            backgroundColor: "rgb(255, 255, 255, 0.1)",
                            padding: "10px 10px",
                            borderRadius: "20px",
                            fontSize: "20px",
                          }}
                        />
                      </a>
                    </Col>
                    <Col>
                      <a
                        href={
                          contactSider?.length || contactSider === null
                            ? "https://www.youtube.com/channel/UC04S84vUkUYwAgRIe9t4X4Q"
                            : contactSider[3]?.Paragraph
                        }
                      >
                        <YoutubeFilled
                          style={{
                            color: "white",
                            backgroundColor: "rgb(255, 255, 255, 0.1)",
                            padding: "10px 10px",
                            borderRadius: "20px",
                            fontSize: "20px",
                          }}
                        />
                      </a>
                    </Col>
                  </Row>
                </Row>
              </Row>
            </Col>
          </>
        ) : null}
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row
            style={{
              flexDirection: "column",
              border: "1px solid rgba(255, 50, 0, 0.1)",
              margin: "50px 30px 50px 60px",
              padding: biggerThan900
                ? "30px 0px 0px 30px"
                : "25px 25px 0px 25px",
              borderRadius: "17px",
            }}
          >
            <Col>
              <Text
                strong
                style={{
                  fontSize: "20px",
                }}
              >
                ¿Tienes una empresa y necesitas un auto?
              </Text>
            </Col>
            <Col style={{ paddingBottom: "30px" }}>
              <Text>
                Llena el siguiente formulario y te responderemos a la brevedad.
              </Text>
            </Col>
            <Col>
              <Form
                name="contact"
                initialValues={{ contact: [[{}]] }}
                form={form}
                onFinish={onFinish}
                labelCol={{
                  span: 24,
                }}
                layout="vertical"
              >
                <Form.Item
                  name="rut"
                  rules={[
                    { required: true, message: "Ingrese el rut" },
                    {
                      validator: (_, value) => {
                        if (validate(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject("El rut ingresado es inválido");
                      },
                    },
                  ]}
                  normalize={(value) => format(value)}
                  label="RUT Empresa"
                  style={{ fontWeight: "bold", marginBottom: "40px" }}
                >
                  <Input style={{ width: "90%" }} />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Razón Social" },
                    {
                      max: 20,
                      message: "Debe tener máximo 20 caracteres",
                    },
                    {
                      pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9]+$/,
                      message:
                        "La razón social no puede incluir caracteres especiales",
                    },
                  ]}
                  name="company-name"
                  label="Razón Social"
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Ingrese el tipo de vehiculo" },
                  ]}
                  name="vehicle-name"
                  label="¿Qué vehículo necesitas?"
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                >
                  <Select
                    suffixIcon={<CaretDownOutlined />}
                    id="vehicle-selector"
                  >
                    <Select.Option value="Furgón pasajeros">
                      Furgón pasajeros
                    </Select.Option>
                    <Select.Option value="Furgón grande">
                      Furgón grande
                    </Select.Option>
                    <Select.Option value="Furgón pequeño">
                      Furgón pequeño
                    </Select.Option>
                    <Select.Option value="Camión">Camión</Select.Option>
                    <Select.Option value="Camión pequeño">
                      Camión pequeño
                    </Select.Option>
                    <Select.Option value="Camioneta">Camioneta</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Ingrese la cantidad de unidades",
                    },
                    {
                      pattern: /^[+]*\d{1,3}$/,
                      message: "Ingrese un número válido",
                    },
                  ]}
                  name="vehicle-number"
                  label="¿Cuántas unidades?"
                  style={{ fontWeight: "bold", marginBottom: "40px" }}
                >
                  <InputNumber
                    min={1}
                    max={999}
                    defaultValue={1}
                    style={{ width: "9%" }}
                  />
                </Form.Item>
                <Divider />
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese su nombre",
                    },
                    {
                      whitespace: true,
                    },
                    {
                      min: 2,
                      message: "Debe tener al menos 2 caracteres",
                    },
                    {
                      max: 50,
                      message: "Debe tener máximo 50 caracteres",
                    },
                    {
                      pattern: /^[A-Za-z\s.'Ññ]+$/,
                      message: "El nombre solo debe incluir letras.",
                    },
                  ]}
                  name="firstName"
                  label="¿Cuál es tu nombre?"
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese su apellido",
                    },
                    {
                      whitespace: true,
                    },
                    {
                      min: 2,
                      message: "Debe tener al menos 2 caracteres",
                    },
                    {
                      max: 50,
                      message: "Debe tener máximo 50 caracteres",
                    },
                    {
                      pattern: /^[A-Za-z\s.'Ññ]+$/,
                      message: "El apellido solo debe incluir letras.",
                    },
                  ]}
                  name="lastName"
                  label="¿Cuál es tu apellido?"
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                >
                  <Input />
                </Form.Item>
                <Input.Group compact>
                  <Form.Item
                    label={<Text strong>Prefijo</Text>}
                    name="phonePrefix"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select disabled defaultValue="56" style={{ width: 80 }}>
                      <Option value={"56"}>+56</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={<Text strong>Telefono</Text>}
                    name="phoneNumber"
                    style={{
                      width: "80%",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Ingrese el número de telefono",
                      },
                      {
                        pattern: /^[+]*\d{9}$/,
                        message:
                          "Ingrese un número válido, sin letras y de 9 dígitos.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Input.Group>
                <Form.Item
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                  name="email"
                  label="Correo electrónico"
                  rules={[
                    {
                      type: "email",
                      message: "Por favor ingrese un correo válido",
                    },
                    {
                      required: true,
                      message: "Por favor ingrese el correo",
                    },
                    {
                      max: 40,
                      message:
                        "No puede ingresar más de 40 caracteres en un correo",
                    },
                    {
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Row
                  align="middle"
                  justify="center"
                  style={{ marginTop: "55px", marginBottom: "30px" }}
                >
                  <Button type="primary" onClick={() => form.submit()}>
                    Enviar
                  </Button>
                </Row>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                  ref={reRef}
                  size="invisible"
                />
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>

      <Footer />
    </div>
  );
}

export default Contact;
