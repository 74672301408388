import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "antd";
import Button from "../../../Button";
import Logo from "./../../../../assets/img/gama-logo.svg";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanError,
  error,
  getStatus,
  getUserData,
} from "../../../../store/auth";
import { Link, useNavigate } from "react-router-dom";
import { STATUS } from "../../../../utils/status";
import Notifications from "../../../Notifications";
import { useMediaPredicate } from "react-media-hook";
import Burger from "../Burger";
import { useScroll } from "../../../../utils/useScroll";
import {
  getHomeHeaderContent,
  getNavbarContent,
} from "../../../../store/strapi/gamaleasing";

const Header = ({ urlImage, header = true }) => {
  const [showAuthForm, setShowAuthForm] = useState(false);
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [visible, setVisible] = useState(false);
  const [isHome, setIsHome] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = useSelector(getStatus);
  const hasError = useSelector(error);
  const { scrollDirection } = useScroll();
  const headerContent = useSelector(getHomeHeaderContent);
  const navbarContent = useSelector(getNavbarContent);

  const styles = {
    active: {
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };

  const redirectToDashboard = () => navigate("/dashboard");

  useEffect(() => {
    if (status === STATUS.SUCCESS) {
      redirectToDashboard();
    }

    if (status === "failed") {
      Notifications.error("Error", hasError);
      setTimeout(() => dispatch(cleanError()), 3000);
    }
  }, [status, hasError, dispatch]);

  const openAuthForm = () => setShowAuthForm(true);

  useEffect(() => {
    if (
      window.location.pathname === "/flota" ||
      window.location.pathname === "/contacto"
    ) {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);

  return (
    <Row
      className={"header-contain-small"}
      style={{
        backgroundImage: `url(${
          headerContent?.bannerImage.url
            ? headerContent?.bannerImage.url
            : urlImage
        })`,
      }}
    >
      {header && (
        <Col
          id="navbar"
          style={
            scrollDirection === "down" || !scrollDirection
              ? styles.active
              : styles.hidden
          }
        >
          <nav className="home-nav-container">
            <ul>
              <li>
                <a
                  target="_blank"
                  href={
                    navbarContent?.topLinks[0].Hreference ||
                    "https://gamamobility.cl/"
                  }
                  rel="noreferrer"
                >
                  {navbarContent?.topLinks[0].label || "GAMA MOBILITY"}
                </a>
              </li>
              <li>
                <Link
                  style={{
                    color:
                      window.location.pathname === "/" ? "#FF3200" : "white",
                  }}
                  to={"/"}
                >
                  {navbarContent?.topLinks[1].label || "GAMA LEASING"}
                </Link>
              </li>
              <li>
                <a
                  href={
                    navbarContent?.topLinks[2].Hreference ||
                    "https://www.gamarent.cl/"
                  }
                >
                  {navbarContent?.topLinks[2].label || "RENT A CAR"}
                </a>
              </li>
              <li>
                <a
                  href={
                    navbarContent?.topLinks[3].Hreference ||
                    "https://www.tripp.cl/"
                  }
                >
                  {navbarContent?.topLinks[3].label || "TRIPP"}
                </a>
              </li>
              <li>
                <Link to={"/usados"}>
                  {navbarContent?.topLinks[4].label || "GAMA USADOS"}
                </Link>
              </li>
            </ul>
          </nav>
          <header style={{ background: "rgba(45, 45, 45, 0.4)" }}>
            <div className="home-nav-items">
              <div className="home-nav-logo">
                <Link to={"/"}>
                  <Image
                    preview={false}
                    src={navbarContent?.logoUrl || Logo}
                    width={120}
                  />
                </Link>
              </div>
              <div className="group-nav-items">
                <div
                  className="nav-container-buttons"
                  style={{
                    transition: "all ease-out 1s;",
                    opacity: `${visible ? "0" : "1"}`,
                    pointerEvents: `${visible ? "none" : "auto"}`,
                  }}
                >
                  <Link to="/contacto">
                    <Button
                      onClick={() => {}}
                      className="gama-outline talk-button"
                    >
                      {navbarContent?.navButtons[0].Label || "Nuevo Arriendo"}
                    </Button>
                  </Link>
                  <Link to="/login">
                    <Button id="auth-modal-button">
                      {biggerThan900
                        ? navbarContent?.navButtons[1].Label ||
                          "Portal Clientes"
                        : showAuthForm
                        ? "Cerrar"
                        : navbarContent?.navButtons[1].Label ||
                          "Portal Clientes"}
                    </Button>
                  </Link>
                </div>
                <div className="container-menu">
                  {biggerThan900 ? (
                    <div className="container-navlinks">
                      {isHome ? (
                        <>
                          <a smooth href="#beneficios">
                            {navbarContent?.navLinks[0].label || "Beneficios"}
                          </a>
                          <a smooth href="#about-us">
                            {navbarContent?.navLinks[1].label ||
                              "¿Qué hacemos?"}
                          </a>
                        </>
                      ) : (
                        <>
                          <Link to="/">
                            {navbarContent?.navLinks[0].label || "Beneficios"}
                          </Link>
                          <Link to="/">
                            {navbarContent?.navLinks[1].label ||
                              "¿Qué hacemos?"}
                          </Link>
                        </>
                      )}
                      <Link to="/flota">
                        {navbarContent?.navLinks[2].label || "Flota"}
                      </Link>
                    </div>
                  ) : (
                    <Burger
                      modal={visible}
                      onClose={() => {
                        setVisible(!visible);
                        setShowAuthForm(false);
                      }}
                      openAuthForm={openAuthForm}
                    />
                  )}
                </div>
              </div>
            </div>
          </header>
        </Col>
      )}
    </Row>
  );
};

export default Header;
