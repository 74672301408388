import { Layout, notification } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import Banner from "./Banner";
import Footer from "./Footer";
import HomeImage from "../../../assets/img/home.webp";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import {
  getGamaLeasingHomeContent,
  getGamaLeasingFleetContent,
  getGamaLeasingContactContent,
} from "../../../store/strapi/gamaleasing/thunks";
import {
  getHomeHeaderContent,
  getStatus,
} from "../../../store/strapi/gamaleasing";
import LoadingStrapi from "../../../domain/LoadingStrapi";
import { STATUS } from "../../../utils/status";

const HomeLayout = ({ children }) => {
  const dispatch = useDispatch();
  const leasingStatus = useSelector(getStatus);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getGamaLeasingHomeContent());
    dispatch(getGamaLeasingFleetContent());
    dispatch(getGamaLeasingContactContent());
  }, []);

  const homeHeader = useSelector(getHomeHeaderContent);

  useEffect(() => {
    document.body.style.overflowY = "hidden";

    if (leasingStatus === STATUS.SUCCESS) {
      document.body.style.overflowY = "scroll";
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  }, [leasingStatus]);

  useEffect(() => {
    const token = localStorage.getItem("gama.token");
    if (token === "undefined") localStorage.removeItem("gama.token");
  }, []);

  return (
    <Layout>
      {loading && (
        <div style={{ zIndex: "9999", position: "relative", opacity: 1 }}>
          <LoadingStrapi
            className={`spinner ${
              loading ? "spinner-visible" : "spinner-hidden"
            }`}
          />
        </div>
      )}

      <Banner
        title={
          homeHeader?.length === 0 || homeHeader === null
            ? "Leasing Operativo"
            : homeHeader?.title
        }
        subtitle={
          homeHeader?.length === 0 || homeHeader === null
            ? "Soluciones a medida para la operación de tu empresa"
            : homeHeader?.detail
        }
        urlImage={
          homeHeader?.length === 0 || homeHeader === null
            ? HomeImage
            : homeHeader?.bannerImage?.url
        }
        button //default falsesß
      />

      <Layout>
        <Content>{children}</Content>
      </Layout>

      <Footer />
    </Layout>
  );
};

export default HomeLayout;
