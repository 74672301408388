import {
  CaretDownOutlined,
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  MailOutlined,
  PhoneOutlined,
  YoutubeFilled,
} from "@ant-design/icons";
import {
  Col,
  Form,
  Image,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";

import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/index";
import Footer from "../../components/layouts/HomeLayout/Footer";
import ContactBanner from "../../assets/img/contact-banner.webp";
import { useMediaPredicate } from "react-media-hook";
import { sendUsedPayload } from "../../requests/contact";
import { Link } from "react-router-dom";
import Burger from "../../components/layouts/HomeLayout/Burger";
import { useScroll } from "../../utils/useScroll";
import Logo from "../../assets/img/logo-usados.webp";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { getGamaUsadosContactContents } from "../../store/strapi/gamausados/thunks";
import {
  getUsedContactHeaderContent,
  getUsedContactSiderContent,
} from "../../store/strapi/gamausados";

function Contact() {
  const [form] = Form.useForm();
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const reRef = useRef();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isHome, setIsHome] = useState();

  const { TextArea } = Input;

  useEffect(() => {
    dispatch(getGamaUsadosContactContents());
  }, []);

  const usedContactHeader = useSelector(getUsedContactHeaderContent);
  const usedContactSider = useSelector(getUsedContactSiderContent);

  useEffect(() => {
    form.setFieldsValue({
      "vehicle-number": "1",
      phonePrefix: "56",
      message: "",
    });
  }, []);

  const onFinish = async (values) => {
    const token = await reRef.current.executeAsync();
    reRef.current.reset();

    try {
      values.phoneNumber = +values.phoneNumber;
      values.phonePrefix = +values.phonePrefix;
      const payload = { ...values };
      const { data } = await sendUsedPayload({ ...payload, reCaptcha: token });

      if (data.success)
        return Modal.success({
          content: "Datos enviados con éxito",
          onOk() {
            window.location.replace("/");
          },
        });
    } catch (error) {
      if (error)
        return Modal.error({
          content: "Ha ocurrido un error al enviar los datos",
        });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      phonePrefix: "56",
      message: " ",
    });
  }, []);

  const { Option } = Select;

  const { scrollDirection } = useScroll();

  useEffect(() => {
    if (
      window.location.pathname === "/contacto-usado" ||
      window.location.pathname === "/contacto"
    ) {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);

  const burguerData = [
    {
      title: "Sucursales",
      link: "/sucursales",
    },
    {
      title: "Nuestros servicios",
      link: "#servicios",
    },
    {
      title: "Quienes Somos",
      link: "#nosotros",
    },
    { title: "Beneficios", link: "#beneficios" },
    { title: "Publicaciones", link: "#publicaciones" },
  ];

  const styles = {
    active: {
      // visibility: "visible",
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      // visibility: "hidden",
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };

  const { Text } = Typography;

  console.log(usedContactHeader);
  return (
    <div>
      <Layout>
        <section
          className="home-banner-container"
          id="header"
          style={{
            height: `60vh`,
          }}
        >
          <Row
            className="header-contain"
            style={{
              backgroundImage: `url(${
                usedContactHeader?.length === 0 || usedContactHeader === null
                  ? ContactBanner
                  : usedContactHeader?.bannerImage[0].url
              })`,
            }}
          >
            <Col
              id="navbar"
              style={
                scrollDirection === "down" || !scrollDirection
                  ? styles.active
                  : styles.hidden
              }
            >
              <nav className="home-nav-container">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href={"https://gamamobility.cl/"}
                      rel="noreferrer"
                    >
                      GAMA MOBILITY
                    </a>
                  </li>
                  <li>
                    <Link
                      style={{
                        color:
                          window.location.pathname === "/"
                            ? "#FF3200"
                            : "white",
                      }}
                      to={"/"}
                    >
                      GAMA LEASING
                    </Link>
                  </li>

                  <li>
                    <a href={"https://www.gamarent.cl/"}>RENT A CAR</a>
                  </li>
                  <li>
                    <a href={"https://www.tripp.cl/"}>TRIPP</a>
                  </li>
                  <li>
                    <Link to={"/usados"}>GAMA USADOS</Link>
                  </li>
                </ul>
              </nav>
              <header>
                <div className="home-nav-items">
                  <div className="home-nav-logo">
                    <Link to={"/"}>
                      <Image
                        preview={false}
                        src={Logo}
                        style={{ width: "130px" }}
                      />
                    </Link>
                  </div>
                  <div className="group-nav-items">
                    <div
                      className="nav-container-buttons"
                      style={{
                        transition: "all ease-out 1s;",
                        opacity: `${visible ? "0" : "1"}`,
                        pointerEvents: `${visible ? "none" : "auto"}`,
                      }}
                    >
                      <Link to="/contacto-usado">
                        <Button
                          onClick={() => {}}
                          className="gama-outline talk-button"
                        >
                          Conversemos
                        </Button>
                      </Link>
                    </div>
                    <div className="container-menu">
                      {biggerThan900 ? (
                        <div className="container-navlinks">
                          {isHome ? (
                            <>
                              <a smooth href="#beneficios">
                                Beneficios
                              </a>

                              <a smooth href="#mayorista">
                                ¿Eres mayorista?
                              </a>
                            </>
                          ) : (
                            <>
                              <Link to="/usados">Beneficios</Link>

                              <Link to="/usados">¿Eres mayorista?</Link>
                            </>
                          )}
                        </div>
                      ) : (
                        <Burger
                          data={burguerData}
                          boton={false}
                          modal={visible}
                          onClose={() => {
                            setVisible(!visible);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </header>
            </Col>
          </Row>
          <div className="banner-title-container">
            <h1
              style={{
                textAlign: "left",
                paddingLeft: "8.125rem",
              }}
            >
              {usedContactHeader?.length === 0 || usedContactHeader === null
                ? "Gama usados"
                : usedContactHeader?.title?.Title}
            </h1>
            <h3
              style={{
                textAlign: "left",
                lineHeight: "35px",
                fontSize: "40px",
                fontWeight: "400",

                paddingLeft: "8.125rem",
              }}
            >
              {usedContactHeader?.length === 0 || usedContactHeader === null
                ? "Programa una consulta gratuita con nuestro equipo."
                : usedContactHeader?.title?.Detail}
            </h3>
          </div>
        </section>
      </Layout>
      <Row>
        <Col span={7}>
          {biggerThan900 ? (
            <Row
              style={{
                backgroundColor: "#2D2D2D",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "left",
              }}
            >
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Text
                  style={{
                    color: "white",
                    marginBottom: "10px",
                    marginTop: " 40px",
                  }}
                >
                  Contacto
                </Text>
                <Row
                  justify="start"
                  style={{ color: "white", marginBottom: "5px" }}
                >
                  <Col>
                    <PhoneOutlined
                      style={{ color: "#FF3200", marginRight: "15px" }}
                    />
                  </Col>
                  <Col style={{ color: "white" }}>+56 93 918 5879</Col>
                </Row>
                <Row>
                  <Col>
                    <MailOutlined
                      style={{ color: "#FF3200", marginRight: "15px" }}
                    />
                  </Col>
                  <Col style={{ color: "white" }}>attr@gamaleasing.cl </Col>
                </Row>
                <Text
                  style={{
                    color: "white",
                    marginTop: "30px",
                    marginBottom: "15px",
                  }}
                >
                  Siguenos
                </Text>
                <Row justify="space-evenly" style={{ width: "200px" }}>
                  <Col>
                    <a
                      href={
                        usedContactSider || usedContactSider === null
                          ? "https://www.facebook.com/gamaleasing2013"
                          : usedContactSider?.SocialMedia[0]?.Hreference
                      }
                    >
                      <FacebookFilled
                        style={{
                          color: "white",
                          backgroundColor: "rgb(255, 255, 255, 0.1)",
                          padding: "10px 10px",
                          borderRadius: "20px",
                          fontSize: "20px",
                        }}
                      />
                    </a>
                  </Col>
                  <Col>
                    <a
                      href={
                        usedContactSider || usedContactSider === null
                          ? "https://www.instagram.com/gamaleasing/"
                          : usedContactSider?.SocialMedia[1]?.Hreference
                      }
                    >
                      <InstagramFilled
                        style={{
                          color: "white",
                          backgroundColor: "rgb(255, 255, 255, 0.1)",
                          padding: "10px 10px",
                          borderRadius: "20px",
                          fontSize: "20px",
                        }}
                      />
                    </a>
                  </Col>
                  <Col>
                    <a
                      href={
                        usedContactSider || usedContactSider === null
                          ? "https://www.linkedin.com/company/gamaleasing/"
                          : usedContactSider?.SocialMedia[2]?.Hreference
                      }
                    >
                      <LinkedinFilled
                        style={{
                          color: "white",
                          backgroundColor: "rgb(255, 255, 255, 0.1)",
                          padding: "10px 10px",
                          borderRadius: "20px",
                          fontSize: "20px",
                        }}
                      />
                    </a>
                  </Col>
                  <Col>
                    <a
                      href={
                        usedContactSider || usedContactSider === null
                          ? "https://www.youtube.com/channel/UC04S84vUkUYwAgRIe9t4X4Q"
                          : usedContactSider?.SocialMedia[3]?.Hreference
                      }
                    >
                      <YoutubeFilled
                        style={{
                          color: "white",
                          backgroundColor: "rgb(255, 255, 255, 0.1)",
                          padding: "10px 10px",
                          borderRadius: "20px",
                          fontSize: "20px",
                        }}
                      />
                    </a>
                  </Col>
                </Row>
              </Row>
            </Row>
          ) : null}
        </Col>
        <Col xs={24} sm={16} md={12}>
          <Row
            style={{
              flexDirection: "column",
              border: "1px solid rgba(255, 50, 0, 0.1)",
              margin: "50px 30px 50px 60px",
              padding: "50px 50px 0px 50px",
              borderRadius: "17px",
            }}
          >
            <Col>
              <Text strong style={{ fontSize: "20px" }}>
                ¿Necesitas un auto?
              </Text>
            </Col>
            <Col style={{ paddingBottom: "30px" }}>
              <Text>
                Llena el siguiente formulario y te responderemos a la brevedad.
              </Text>
            </Col>
            <Col>
              <Form
                name="contactUsed"
                initialValues={{ contactUsed: [[{}]] }}
                form={form}
                onFinish={onFinish}
                labelCol={{
                  span: 24,
                }}
                layout="vertical"
              >
                <Form.Item
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese su automotora/compraventa",
                    },
                    {
                      whitespace: true,
                    },
                    {
                      min: 2,
                      message: "Debe tener al menos 2 caracteres",
                    },
                    {
                      max: 50,
                      message: "Debe tener máximo 50 caracteres",
                    },
                    {
                      pattern: /^[A-Za-z\s.'Ññ]+$/,
                      message:
                        "La automotora/compraventa solo debe incluir letras.",
                    },
                  ]}
                  name="firstName"
                  label="¿Cuál es tu automotora/compraventa?"
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  required={false}
                  style={{
                    width: "90%",
                    fontWeight: "bold",
                    marginBottom: "40px",
                  }}
                  name="email"
                  label="¿Cuál es tu correo electronico?"
                  rules={[
                    {
                      type: "email",
                      message: "Por favor ingrese un correo válido",
                    },
                    {
                      required: true,
                      message: "Por favor ingrese el correo",
                    },
                    {
                      max: 40,
                      message:
                        "No puede ingresar más de 40 caracteres en un correo",
                    },
                    {
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Input.Group compact>
                  <Form.Item
                    required={false}
                    label={<Text strong>Prefijo</Text>}
                    name="phonePrefix"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      suffixIcon={<CaretDownOutlined />}
                      disabled
                      defaultValue="56"
                      style={{ width: 80 }}
                    >
                      <Option value={"56"}>+56</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    required={false}
                    label={<Text strong>Telefono</Text>}
                    name="phoneNumber"
                    style={{
                      width: "80%",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Ingrese el número de telefono",
                      },
                      {
                        //Numeros, signo +
                        pattern: /^[+]*\d{9}$/,
                        message:
                          "Ingrese un número válido, sin letras y de 9 dígitos.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Input.Group>

                <Form.Item
                  required={false}
                  rules={[
                    {
                      required: false,
                    },
                    {
                      max: 500,
                      message: "Debe tener máximo 500 caracteres",
                    },
                    {
                      pattern: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s0-9@.]+$/,
                      message: "No puede incluir caracteres especiales.",
                    },
                  ]}
                  style={{
                    width: "90%",
                    marginBottom: "40px",
                  }}
                  name="message"
                  label={<Text strong>Comentario</Text>}
                >
                  <TextArea />
                </Form.Item>

                <Row
                  align="middle"
                  justify="center"
                  style={{ marginTop: "55px", marginBottom: "30px" }}
                >
                  <Button type="primary" onClick={() => form.submit()}>
                    Enviar
                  </Button>
                </Row>

                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                  ref={reRef}
                  size="invisible"
                />
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>

      <Footer logo={Logo} />
    </div>
  );
}

export default Contact;
