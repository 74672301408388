import React, { useRef } from "react";
import { Footer as AntFooter } from "antd/lib/layout/layout";
import {
  ArrowUpOutlined,
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  PhoneFilled,
  YoutubeFilled,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Image, Divider } from "antd";
import Button from "../../../Button";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import { getFooterContent } from "../../../../store/strapi/gamaleasing/index";
import Bh from "./../../../../assets/img/gama-bh.webp";
import "./index.less";

const LogoSection = ({ logo, logoList }) => {
  return (
    <div className="footer-gama-logo">
      <Image
        preview={false}
        src={logoList?.length ? logoList[0]?.url : logo}
        width={180}
      />
    </div>
  );
};

const SocialNetworksSection = ({ socialNetworkList }) => {
  return (
    <div className="footer-social-networks">
      <ul>
        <li>
          <a
            key={
              socialNetworkList
                ? socialNetworkList[0].Hreference
                : "https://www.facebook.com/gamaleasing2013"
            }
            href={
              socialNetworkList
                ? socialNetworkList[0].Hreference
                : "https://www.facebook.com/gamaleasing2013"
            }
          >
            <FacebookFilled />
          </a>
        </li>
        <li>
          <a
            key={
              socialNetworkList
                ? socialNetworkList[1].Hreference
                : "https://www.instagram.com/gamaleasing/"
            }
            href={
              socialNetworkList
                ? socialNetworkList[1].Hreference
                : "https://www.instagram.com/gamaleasing/"
            }
          >
            <InstagramFilled />
          </a>
        </li>
        <li>
          <a
            key={
              socialNetworkList
                ? socialNetworkList[2].Hreference
                : "https://www.linkedin.com/company/gamaleasing/"
            }
            href={
              socialNetworkList
                ? socialNetworkList[2].Hreference
                : "https://www.linkedin.com/company/gamaleasing/"
            }
          >
            <LinkedinFilled />
          </a>
        </li>
        <li>
          <a
            key={
              socialNetworkList
                ? socialNetworkList[3].Hreference
                : "https://www.youtube.com/channel/UC04S84vUkUYwAgRIe9t4X4Q"
            }
            href={
              socialNetworkList
                ? socialNetworkList[3].Hreference
                : "https://www.youtube.com/channel/UC04S84vUkUYwAgRIe9t4X4Q"
            }
          >
            <YoutubeFilled />
          </a>
        </li>
      </ul>
    </div>
  );
};

const BhLogoSection = ({ logoList }) => {
  return (
    <div className="footer-bh-logo">
      <Image
        preview={false}
        src={logoList?.length ? logoList[1]?.url : Bh}
        width={106}
      />
    </div>
  );
};

const AboutSection = ({ footerContent, footerData }) => {
  return (
    <div className="footer-about">
      <h5>
        {footerContent?.FooterLinks?.length
          ? footerContent?.FooterLinks[0].Title
          : "Empresa"}
      </h5>
      <ul>
        {footerData?.map((item) => (
          <li key={item.title}>
            <a key={item.title} href={item.link}>
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const InformationSection = ({ footerContent, footerInfo }) => {
  return (
    <div className="footer-information">
      <h5>
        {footerContent?.FooterLinks?.length
          ? footerContent?.FooterLinks[1].Title
          : "Infomación"}
      </h5>
      <ul>
        {footerInfo?.map((item) => (
          <li key={item.title}>
            <a key={item.title} href={item.link}>
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const CallCenterSection = ({ contactCard }) => {
  return (
    <div className="footer-callcenter">
      <h5>{contactCard?.Title || "¿Quieres saber más?"}</h5>
      <p>{contactCard?.Paragrapgh || "Comunícate a nuestro call center"}</p>
      <div className="phone">
        <PhoneFilled
          style={{ transform: "rotate(115deg)", marginRight: "5px" }}
        />
        <h3>600 586 8686</h3>
      </div>
      <p>Para emergencias</p>
      <div className="phone">
        <PhoneFilled
          style={{ transform: "rotate(115deg)", marginRight: "5px" }}
        />
        <h3>+56 2 2757 4450</h3>
      </div>
    </div>
  );
};

const NewsletterSection = ({ newsLetter }) => {
  const reRef = useRef();

  return (
    <div className="footer-newsletter">
      {/* <h5>{newsLetter?.Title || 'Suscribete a nuestros newsletter'}</h5>
      <Form
        onFinish={async (form) => {
          const client = axios.create();
          const token = await reRef.current.executeAsync();
          reRef.current.reset();

          try {
            await client({
              url: `${BASE_USERS_API_URL}/newsletter/signup`,
              method: "POST",
              data: { ...form, reCaptcha: token },
            });
            Notifications.success(
              "Suscripción exitosa",
              `Se ha suscrito con exito.`
            );
          } catch (error) {
            Notifications.error(
              "Suscripción fallida",
              `No lo hemos suscrito.`
            );
          }
        }}
        onFinishFailed={() => {
          Notifications.error(
            "Error",
            "El campo email no es valido!."
          );
        }}
      >
        <p>{newsLetter?.Paragraph || 'Conoce nuestras ofertas y promociones'}</p>
        <Form.Item
          name="mail"
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Por favor ingrese un email valido!'
            }
          ]}
        >
          <Input
            name="mail"
            placeholder={newsLetter?.InputPlaceholder || 'nombre@email.com'}
          />
        </Form.Item>
        <Button htmlType="submit">{newsLetter?.NewsletterButton?.Label || 'Suscribirse'}</Button>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
          ref={reRef}
          size="invisible"
        />
      </Form> */}
    </div>
  );
};

const SignatureSection = ({ bottomLinks, footerContent }) => {
  return (
    <div className="footer-signature">
      <hr />
      <div className="signature-contain">
        <div className="footer-links">
          {bottomLinks?.map((link) => (
            <>
              <Link to={link.Hreference}>{link.label}</Link>
              <Divider type="vertical" className="divider" />
            </>
          ))}
        </div>
        <div className="all-rights-reserved">
          {footerContent?.BottomArea?.Trademark ||
            "© 2022 GAMALEASING. Todos los derechos reservados."}
        </div>
      </div>
    </div>
  );
};

const Footer = ({ logo }) => {
  const footerContent = useSelector(getFooterContent);
  const gamaLinks = footerContent?.FooterLinks[0]?.FooterLink;
  const infoLinks = footerContent?.FooterLinks[1]?.FooterLink;
  const logoList = footerContent?.Logo;
  const contactCard = footerContent?.ContactCard;
  const newsLetter = footerContent?.FooterNewsletter;
  const bottomLinks = footerContent?.BottomArea.BottomLinks;
  const socialNetworkList = footerContent?.SocialNetwork;

  const footerInfo = [
    {
      title: footerContent ? infoLinks[0].label : "Sucursales",
      link: footerContent ? infoLinks[0].Hreference : "/sucursales",
    },
    {
      title: footerContent ? infoLinks[1].label : "Siniestros",
      link: footerContent
        ? infoLinks[1].Hreference
        : `${process.env.REACT_APP_SINISTER_URL_SITE}/home`,
    },
    {
      title: footerContent ? infoLinks[2].label : "Pagos Online",
      link: footerContent ? infoLinks[2].Hreference : "/pagos",
    },
    {
      title: footerContent ? infoLinks[3].label : "Preguntas Frecuentes",
      link: footerContent ? infoLinks[3].Hreference : "/FAQ",
    },
    {
      title: footerContent ? infoLinks[4].label : "Publicaciones",
      link: footerContent ? infoLinks[4].Hreference : "/posts",
    },
    {
      title: footerContent ? infoLinks[5].label : "¡Trabaja con nosotros!",
      link: footerContent
        ? infoLinks[5].Hreference
        : "https://gamaleasing.trabajando.cl/",
    },
  ];

  const footerData = [
    {
      title: footerContent ? gamaLinks[0].label : "Gama Mobility",
      link: footerContent
        ? gamaLinks[0].Hreference
        : "https://gamamobility.cl/",
    },
    {
      title: footerContent ? gamaLinks[1].label : "Gama Leasing",
      link: footerContent ? gamaLinks[1].Hreference : "/",
    },
    {
      title: footerContent ? gamaLinks[2].label : "Tripp",
      link: footerContent ? gamaLinks[2].Hreference : "https://www.tripp.cl/",
    },
    {
      title: footerContent ? gamaLinks[3].label : "Rent a Car",
      link: footerContent
        ? gamaLinks[3].Hreference
        : "https://www.gamarent.cl/",
    },
    {
      title: footerContent ? gamaLinks[4].label : "Gama Usados",
      link: footerContent ? gamaLinks[4].Hreference : "/usados",
    },
    {
      title: footerContent ? gamaLinks[5].label : "Nosotros",
      link: footerContent
        ? gamaLinks[5].Hreference
        : "https://gamamobility.cl/mobility-nosotros",
    },
  ];

  return (
    <AntFooter
      style={{ paddingTop: "40px" }}
      className="container-footer"
      id="home-layout-footer"
    >
      <div className="contain">
        <div className="footer-header">
          <LogoSection logo={logo} logoList={logoList} />
          <SocialNetworksSection socialNetworkList={socialNetworkList} />
          <BhLogoSection logoList={logoList} />
        </div>

        <AboutSection footerContent={footerContent} footerData={footerData} />

        <InformationSection
          footerContent={footerContent}
          footerInfo={footerInfo}
        />

        <CallCenterSection contactCard={contactCard} />

        <NewsletterSection newsLetter={newsLetter} />
      </div>

      <SignatureSection
        bottomLinks={bottomLinks}
        footerContent={footerContent}
      />

      <HashLink smooth to="#top">
        <Button className="arrow-up-button" icon={<ArrowUpOutlined />} />
      </HashLink>
    </AntFooter>
  );
};

export default Footer;
