import React, { useEffect, useState } from "react";
import Footer from "../../components/layouts/HomeLayout/Footer";
import Camion from "../../assets/img/camion.webp";
import Camioneta from "../../assets/img/camionetas.webp";
import Pequeno from "../../assets/img/camion-pequeno.webp";
import Furgon from "../../assets/img/furgon-grande.webp";
import FurgonPasajerons from "../../assets/img/furgon-pasajeros.webp";
import FurgonPeque from "../../assets/img/furgon-pequeno.webp";
import { Col, Row, Typography } from "antd";
import "./index.css";
import Banner from "../../components/layouts/HomeLayout/Banner";
import FleetBanner from "../../assets/img/fleet-banner.webp";
import { useDispatch, useSelector } from "react-redux";
import { getGamaLeasingFleetContent } from "../../store/strapi/gamaleasing/thunks";
import {
  getFleetGalleryContent,
  getFleetHeaderContent,
} from "../../store/strapi/gamaleasing";
const { Text } = Typography;

function Fleet() {
  const [selected, setSelected] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGamaLeasingFleetContent());
  }, []);

  const fleetContent = useSelector(getFleetGalleryContent);
  const fleetHeader = useSelector(getFleetHeaderContent);
  const footerInfo = [
    { title: "Sucursales", link: "/sucursales" },
    {
      title: "Siniestros",
      link: `${process.env.REACT_APP_SINISTER_URL_SITE}/home`,
    },
    { title: "Pagos Online", link: "/pagos" },
    { title: "Preguntas Frecuentes", link: "/FAQ" },
    { title: "Publicaciones", link: "/posts" },
    {
      title: "¡Trabaja con nosotros!",
      link: "https://gamaleasing.trabajando.cl/",
    },
  ];

  const footerData = [
    {
      title: "Gama Mobility",
      link: "https://gamamobility.cl/",
    },
    {
      title: "Gama Leasing",
      link: "/",
    },
    {
      title: "Tripp",
      link: "https://www.tripp.cl/",
    },
    { title: "Rent a Car", link: "https://www.gamarent.cl/" },
    { title: "Gama Usados", link: "/usados" },
    {
      title: "Nosotros",
      link: "https://gamamobility.cl/mobility-nosotros",
    },
  ];

  const VehicleData = [
    {
      id: "1",
      imagen: Camioneta,
      name: "Camionetas",
      description:
        "Modelos 4x2 y 4x4, con equipamiento especial según los requerimientos del Cliente y desplazamientos de persona, es el modelo ideal para cargas liviana, presentes en las industria Minera, Forestal, Pesquera, Construcción y Sanitaria.",
    },
    {
      id: "2",
      imagen: FurgonPeque,
      name: "Furgón Pequeño",
      description:
        "El furgón pequeño es el modelo ideal para transportes de cargas livianas, presente en industrias tales como: Telecomunicaciones, Transporte, Comercio, Servicios, Courier y Seguridad.",
    },
    {
      id: "3",
      imagen: Furgon,
      name: "Furgón Grande",
      description:
        "El furgón grande es el modelo utilizado para transportes de cargas livianas de volumen, presente en industrias tales como: Courier, Alimentos, Comercio, Servicios y Transporte.",
    },
    {
      id: "4",
      imagen: FurgonPasajerons,
      name: "Furgón Pasajeros",
      description:
        "Es el modelo ideal para transportes de pasajeros, siendo un vehículo que mantiene a los conductores seguros, alertas y cómodos. Se encuentra presente en industrias de: Turismo, Transporte y Comercial.",
    },
    {
      id: "5",
      imagen: Pequeno,
      name: "Camión Pequeño",
      description:
        "Los camiones pequeños brindan la mayor confiabilidad y agilidad al ocuparlo, con equipamiento especial según los requerimientos del Cliente. Estos modelos son utilizados para transportes de carga liviana. Se encuentran presente en industrias de Transporte, Comercio, Alimentos y Servicios.",
    },
    {
      id: "6",
      imagen: Camion,
      name: "Camion",
      description:
        "La linea de camiones ¾ son eficientes y poseen buena capacidad de transporte de cargas semi pesadas o de volumen con equipamiento especial según los requerimientos del Cliente. Se encuentra presentes en industrias de: Tr ansporte, Comercio, Alimentos y Servicios.",
    },
  ];
  return (
    <>
      <Banner
        urlImage={
          fleetHeader?.length || fleetHeader === null
            ? FleetBanner
            : fleetHeader?.bannerImage[0]?.url
        }
        height="60"
        fontSize="24px"
        title={
          fleetHeader?.length === 0 || fleetHeader === null
            ? "Nuestra Flota"
            : fleetHeader.title.Title
        }
        subtitle={
          fleetHeader?.length === 0 || fleetHeader === null
            ? "Tenemos la flota que tu empresa necesita con equipamiento acorde a las necesidades de cada rubro."
            : fleetHeader?.title?.Detail
        }
        titleAlign="left"
      />
      <Row justify="space-evenly" align="middle" style={{ marginTop: "70px" }}>
        {fleetContent?.length === 0 || fleetContent === null
          ? VehicleData?.map((vehicle) => (
              <Col key={vehicle.id} xs={24} sm={12} md={8}>
                <Row
                  onClick={() => setSelected(vehicle.id)}
                  justify="space-evenly"
                  align="middle"
                  style={{ flexDirection: "column" }}
                >
                  <div
                    className={
                      selected === vehicle.id ? "full-bg" : "without-bg"
                    }
                    style={{
                      width: "237px",
                      height: "237px",
                      border: "1px solid rgba(255, 50, 0, 0.3)",
                      position: "relative",
                      borderRadius: "20px",
                    }}
                  />
                  <img
                    className={
                      selected === vehicle.id ? "vehicle-up" : "vehicle-down"
                    }
                    style={{
                      width: "331px",
                      height: "214px",
                      position: "absolute",
                      paddingBottom: "20px",
                    }}
                    alt="medium-vehicle"
                    src={vehicle.imagen}
                  />
                  <Text
                    style={{
                      marginBottom: "20px",
                      marginTop: "20px",
                    }}
                    strong
                  >
                    {vehicle.name}
                  </Text>
                  <Text style={{ width: "336px", marginBottom: "50px" }}>
                    {selected === vehicle.id ? vehicle.description : ""}
                  </Text>
                </Row>
              </Col>
            ))
          : fleetContent &&
            fleetContent?.map((vehicle) => (
              <Col key={vehicle?.id} xs={24} sm={12} md={8}>
                <Row
                  onClick={() => setSelected(vehicle?.id)}
                  justify="space-evenly"
                  align="middle"
                  style={{ flexDirection: "column" }}
                >
                  <div
                    className={
                      selected === vehicle?.id ? "full-bg" : "without-bg"
                    }
                    style={{
                      width: "237px",
                      height: "237px",
                      border: "1px solid rgba(255, 50, 0, 0.3)",
                      position: "relative",
                      borderRadius: "20px",
                    }}
                  />
                  <img
                    className={
                      selected === vehicle?.id ? "vehicle-up" : "vehicle-down"
                    }
                    style={{
                      width: "331px",
                      height: "214px",
                      position: "absolute",
                      paddingBottom: "20px",
                    }}
                    alt="medium-vehicle"
                    src={vehicle?.Icon?.url}
                  />
                  <Text
                    style={{
                      marginBottom: "20px",
                      marginTop: "20px",
                    }}
                    strong
                  >
                    {vehicle?.Title}
                  </Text>
                  <Text style={{ width: "336px", marginBottom: "50px" }}>
                    {selected === vehicle?.id ? vehicle?.Paragraph : ""}
                  </Text>
                </Row>
              </Col>
            ))}
      </Row>
      <Footer info={footerInfo} data={footerData} />
    </>
  );
}

export default Fleet;
