import React, { useEffect, useState } from "react";
import AppLayout from "../../components/layouts/AppLayout";
import {
  getUserRequest,
  removeAccess,
  getUsersGamaRequest,
} from "../../requests/users";
import UsersTable from "./UsersTable";
import { useSelector } from "react-redux";
import UserForm from "./UserForm";
import { getCustomers } from "../../store/customers";
import UserDetails from "./UserDetails";
import useTableQuery from "../../hooks/useTableQuery";
import Notifications from "../../components/Notifications";
import PasswordForm from "./PasswordForm";
import Tabs from "../../components/Tabs";
import { isExecutive, isSuperAdmin, isSupervisor } from "../../utils/roles";
import { getUserData } from "../../store/auth";
import BulkUploadUsersForm from "./BulkUploadUsersForm";
import * as DOMPurify from "dompurify";
import { getCustomerAssignmentRequest } from "../../requests/customers";

const Users = () => {
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showBulkUploadForm, setShowBulkUploadForm] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [documentType, setDocumentType] = useState();
  const [access, setAccess] = useState([]);
  const [users, setUsers] = useState([]);
  const [gamaUsers, setGamaUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [gamaTotal, setGamaTotal] = useState(0);
  const userData = useSelector(getUserData);
  const [tab, setTab] = useState("client");
  const [filters, setFilters] = useState({});
  const handleFilters = (key, value) => {
    setFilters((prevValue) => ({ ...prevValue, [key]: value }));
  };
  const handleSearch = async (value) => {
    setInputValue(value);
    setCurrentPageGama(1);
    setCurrentPage(1);
    let cleanValue = DOMPurify.sanitize(value);
    handleFilters("search", cleanValue.replace(/\./g, ""));
  };
  const [inputValue, setInputValue] = useState("");
  const handleFilterByCustomer = (value) => handleFilters("customer", value);
  const handleFilterByRole = (value) => handleFilters("role", value);
  const { accesses } = useSelector(getUserData);
  const handleClearFilters = () => {
    setCurrentPageGama(1);
    setCurrentPage(1);
    setFilters({});
    setInputValue("");
  };
  const {
    currentPage,
    pageSize,
    handlePaginationChange,
    onSearch,
    query,
    setCurrentPage,
  } = useTableQuery();

  const {
    currentPage: currentPageGama,
    pageSize: pageSizeGama,
    handlePaginationChange: handlePaginationChangeGama,
    onSearch: onSearchGama,
    query: queryGama,
    setCurrentPage: setCurrentPageGama,
  } = useTableQuery();
  const customers = useSelector(getCustomers);
  const [customersData, setCustomersData] = useState([]);
  const formCustomers = customersData.customers
    ? customersData.customers
    : customers;

  const fetchUsers = async (query) => {
    setLoadingTable(true);
    try {
      const response = await getUsersGamaRequest(query, filters, "CLIENT");
      const newObj = response?.data.users.map((item) => ({
        access: {
          id: item.access.id,
          status: item.access.status,
        },
        customer: {
          id: item.customer.id,
          name: item.customer.name,
        },
        email: item.email,
        firstName: item.firstName,
        id: item.id,
        lastName: item.lastName,
        phoneNumber: item.phoneNumber,
        phonePrefix: item.phonePrefix,
        profileImageUrl: item.profileImageUrl,
        role: {
          id: item.role.id,
          code: item.role.code,
        },
        rut: item.rut,
        type: item.type,
        isExpandable: true,
        identifierNumber: item.identifierNumber,
      }));
      setUsers(newObj);
      setTotal(response?.data?.count);
    } catch (error) {
      console.log("error", error);
      Notifications.error("Error", "Error al cargar los usuarios");
    } finally {
      setLoadingTable(false);
    }
  };

  const fetchGamaUsers = async (params, currentFilter) => {
    setLoadingTable(true);
    const response = await getUsersGamaRequest(params, currentFilter);
    const newObj = response?.data.users.map((item) => ({
      access: {
        id: item.access.id,
        status: item.access.status,
      },
      customer: {
        id: item.customer.id,
        name: item.customer.name,
      },
      email: item.email,
      firstName: item.firstName,
      id: item.id,
      lastName: item.lastName,
      phoneNumber: item.phoneNumber,
      phonePrefix: item.phonePrefix,
      profileImageUrl: item.profileImageUrl,
      role: {
        id: item.role.id,
        code: item.role.code,
      },
      rut: item.rut,
      type: item.type,
      isExpandable: true,
      identifierNumber: item.identifierNumber,
    }));
    setLoadingTable(false);
    setGamaUsers(newObj);
    setGamaTotal(response?.data?.count);
  };

  useEffect(() => {
    async function execute() {
      await fetchGamaUsers(queryGama, filters);
    }
    execute();
  }, [queryGama, filters, accesses]);

  useEffect(async () => {
    try {
      const { data } = await getCustomerAssignmentRequest({
        pageSize: 9999,
      });
      setCustomersData(data);
    } catch (error) {
      console.error(error);
    }
  }, [accesses]);

  // [queryGama, filters]);

  const onSelectDetails = async (userId) => {
    try {
      setLoadingDrawer(true);
      setShowDetails(true);
      const { data } = await getUserRequest(userId);
      const originalCustomer = data?.accesses?.[0];
      const filteredAccesses = formCustomers?.map((cust) => {
        return data?.accesses.find(
          (custAcc) => custAcc.customer.id === cust.id
        );
      });

      const removeUndefined = filteredAccesses?.filter(
        (item) =>
          item !== undefined && item !== originalCustomer?.customer?.name
      );

      const sortedAccesses = removeUndefined.sort(
        (a, b) =>
          new Date(a.accessCreatedAt).getTime() -
          new Date(b.accessCreatedAt).getTime()
      );

      const filteredDetailData = {
        accesses: sortedAccesses,
        email: data.email,
        firstName: data.firstName,
        id: data.id,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        phonePrefix: data.phonePrefix,
        profileImageUrl: data.profileImageUrl,
        rut: data.rut,
        type: data.type,
        sessionStartDate: data.sessionStartDate,
        sessionExpirationDate: data.sessionExpirationDate,
      };

      setUserDetails(filteredDetailData);
    } catch (error) {
      console.log("error", error);
      Notifications.error("Error", "Error al cargar el usuario");
    } finally {
      setLoadingDrawer(false);
    }
  };

  const onSelectEdit = async (userId) => {
    try {
      setLoadingDrawer(true);
      const { data } = await getUserRequest(userId);
      console.log(data, "data from detail");
      const originalCustomer = data?.accesses?.[0];
      const filteredAccesses = formCustomers?.map((cust) => {
        return data?.accesses.find(
          (custAcc) => custAcc.customer.id === cust.id
        );
      });

      const removeUndefined = filteredAccesses?.filter(
        (item) =>
          item !== undefined && item !== originalCustomer?.customer?.name
      );

      const sortedAccesses = removeUndefined.sort(
        (a, b) =>
          new Date(a.accessCreatedAt).getTime() -
          new Date(b.accessCreatedAt).getTime()
      );

      const filteredDetailData = {
        accesses: sortedAccesses,
        email: data.email,
        firstName: data.firstName,
        id: data.id,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        phonePrefix: data.phonePrefix,
        profileImageUrl: data.profileImageUrl,
        rut: data.rut,
        type: data.type,
        assignedPlates: data.assignedPlates,
        identifierType: data.identifierType,
        sessionStartDate: data?.sessionStartDate,
        sessionExpirationDate: data?.sessionExpirationDate,
        passportNumber: data?.passportNumber,
      };
      setDocumentType(data.identifierType);
      setUserDetails(filteredDetailData);
      setShowForm(true);
    } catch (error) {
      console.log("error", error);
      Notifications.error("Error", "Error al cargar el usuario");
    } finally {
      setLoadingDrawer(false);
    }
  };

  const onSelectPassword = async (userId) => {
    try {
      setLoadingDrawer(true);
      setShowPasswordForm(true);
      const { data } = await getUserRequest(userId);
      setUserDetails(data);
    } catch (error) {
      console.log("error", error);
      Notifications.error("Error", "Error al cambiar el password");
    } finally {
      setLoadingDrawer(false);
    }
  };

  const onOpenForm = () => {
    setShowForm(true);
  };

  const onOpenBulkUploadForm = () => {
    setShowBulkUploadForm(true);
  };

  const onCloseForm = () => {
    setShowForm(false);
    setUserDetails(null);
  };

  const onClosePasswordForm = () => {
    setShowPasswordForm(false);
    setUserDetails(null);
  };

  const onCloseDetails = () => {
    setUserDetails(null);
    setShowDetails(false);
  };

  const onFormFinish = () => {
    setShowForm(false);
    setUserDetails(null);
    fetchUsers(query);
    fetchGamaUsers(queryGama);
  };

  const onDeleteUser = () => {
    fetchUsers(query);
    fetchGamaUsers(queryGama);
  };

  const onToggleAccessClient = async (accessId) => {
    try {
      await removeAccess(accessId);
      setUsers((prevUsers) => {
        return prevUsers.map((prevUser) => {
          if (prevUser.access.id === accessId) {
            return {
              ...prevUser,
              access: {
                ...prevUser.access,
                status: prevUser.access.status === 1 ? 2 : 1,
              },
            };
          } else {
            return prevUser;
          }
        });
      });
      Notifications.success("Terminado", "Se ha completado la operación");
    } catch (error) {
      console.log("error", error);
      if (error?.status === 401) {
        Notifications.error(
          "Error",
          "No tienes los permisos para realizar esa accion."
        );
      } else {
        Notifications.error("Error", "Error al cambiar el estado del usuario");
      }
    }
  };

  const onToggleAccessGama = async (accessId) => {
    try {
      await removeAccess(accessId);
      setGamaUsers((prevUsers) => {
        return prevUsers.map((prevUser) => {
          if (prevUser.access.id === accessId) {
            return {
              ...prevUser,
              access: {
                ...prevUser.access,
                status: prevUser.access.status === 1 ? 2 : 1,
              },
            };
          } else {
            return prevUser;
          }
        });
      });
      Notifications.success("Terminado", "Se ha completado la operación");
    } catch (error) {
      console.log("error", error);
      if (error?.status === 401) {
        Notifications.error(
          "Error",
          "No tienes los permisos para realizar esa accion."
        );
      } else {
        Notifications.error("Error", "Error al cambiar el estado del usuario");
      }
    }
  };

  useEffect(() => {
    async function execute() {
      await fetchUsers(query, filters);
    }
    execute();
  }, [filters, query, accesses]);

  useEffect(() => {
    async function getUserAccess() {
      setAccess(userData.accesses);
    }

    if (userData) {
      getUserAccess();
    }
  }, []);

  const handleTab = (value) => {
    setTab(value);
  };

  return (
    <AppLayout
      tabs={
        isSuperAdmin(access) || isExecutive(access) || isSupervisor(access)
          ? true
          : false
      }
      noContent={
        isSuperAdmin(access) || isExecutive(access) || isSupervisor(access)
          ? true
          : false
      }
      title="Administrador de Usuarios"
    >
      {isSuperAdmin(access) || isSupervisor(access) || isExecutive(access) ? (
        <Tabs
          handleTab={handleTab}
          client={
            <UsersTable
              tab={tab}
              onDeleteUser={onDeleteUser}
              customers={customers}
              handleClearFilters={handleClearFilters}
              handleSearch={handleSearch}
              handleFilterByCustomer={handleFilterByCustomer}
              filters={filters}
              handleFilterByRole={handleFilterByRole}
              users={users}
              current={currentPage}
              total={total}
              onChange={handlePaginationChange}
              loading={loadingTable}
              pageSize={pageSize}
              onSelectUpdate={onSelectEdit}
              onSelectDetails={onSelectDetails}
              onSelectPassword={onSelectPassword}
              onToggleAccess={onToggleAccessClient}
              onSearch={onSearch}
              onOpenForm={onOpenForm}
              onOpenBulkUploadForm={onOpenBulkUploadForm}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          }
          gama={
            <UsersTable
              tab={tab}
              onDeleteUser={onDeleteUser}
              customers={customers}
              handleSearch={handleSearch}
              handleClearFilters={handleClearFilters}
              handleFilterByCustomer={handleFilterByCustomer}
              filters={filters}
              handleFilterByRole={handleFilterByRole}
              users={gamaUsers}
              current={currentPageGama}
              total={gamaTotal}
              onChange={handlePaginationChangeGama}
              loading={loadingTable}
              pageSize={pageSizeGama}
              onSelectUpdate={onSelectEdit}
              onSelectDetails={onSelectDetails}
              onSelectPassword={onSelectPassword}
              onToggleAccess={onToggleAccessGama}
              onSearch={onSearchGama}
              onOpenForm={onOpenForm}
              onOpenBulkUploadForm={onOpenBulkUploadForm}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          }
        />
      ) : (
        <UsersTable
          tab={tab}
          onDeleteUser={onDeleteUser}
          customers={customers}
          handleSearch={handleSearch}
          handleFilterByCustomer={handleFilterByCustomer}
          filters={filters}
          handleClearFilters={handleClearFilters}
          handleFilterByRole={handleFilterByRole}
          users={users}
          current={currentPage}
          total={total}
          onChange={handlePaginationChange}
          loading={loadingTable}
          pageSize={pageSize}
          onSelectUpdate={onSelectEdit}
          onSelectDetails={onSelectDetails}
          onSelectPassword={onSelectPassword}
          onToggleAccess={onToggleAccessClient}
          onSearch={onSearch}
          onOpenForm={onOpenForm}
          onOpenBulkUploadForm={onOpenBulkUploadForm}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      )}

      {/* CREATE USER */}
      {showForm && (
        <UserForm
          id={new Date().getTime()}
          tab={tab}
          handleClearFilters={handleClearFilters}
          open={showForm}
          onClose={onCloseForm}
          onFormFinish={onFormFinish}
          customers={customers}
          userToUpdate={userDetails}
          documentType={documentType}
          setDocumentType={setDocumentType}
        />
      )}

      {/* EDIT USER */}
      {showForm && userDetails && (
        <UserForm
          id={new Date().getTime()}
          tab={tab}
          handleClearFilters={handleClearFilters}
          open={showForm}
          onClose={onCloseForm}
          onFormFinish={onFormFinish}
          customers={customers}
          userToUpdate={userDetails}
          documentType={documentType}
          setDocumentType={setDocumentType}
        />
      )}

      {showDetails && (
        <UserDetails
          loading={loadingDrawer}
          onClose={onCloseDetails}
          open={showDetails}
          userDetails={userDetails}
        />
      )}

      {showPasswordForm && (
        <PasswordForm
          open={showPasswordForm}
          userData={userData}
          onClose={onClosePasswordForm}
          userDetails={userDetails}
        />
      )}

      {showBulkUploadForm && (
        <BulkUploadUsersForm
          open={showBulkUploadForm}
          onClose={() => setShowBulkUploadForm(false)}
          onFormFinish={onFormFinish}
        />
      )}
    </AppLayout>
  );
};

export default Users;
