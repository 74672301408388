import React from "react";
import HomeLayout from "../../components/layouts/HomeLayout";
import { Col, Divider, Image, Row } from "antd";
import Carousel from "./components/Carousel";
import BannerCamiones from "../../assets/img/gama_banner_Camiones_Furgones-1024x425.webp";
import QueHacemos from "../../assets/img/quehacemos.webp";
import FurgonPasajeros from "../../assets/img/furgon-pasajeros.webp";
import FurgonGrande from "../../assets/img/furgon-grande.webp";
import FurgonPequeno from "../../assets/img/furgon-pequeno.webp";
import Camion from "../../assets/img/camion.webp";
import CamionPequeno from "../../assets/img/camion-pequeno.webp";
import Camioneta from "../../assets/img/camionetas.webp";
import BenefitCard from "./components/BenefitCard";
import Button from "../../components/Button";
import { useMediaPredicate } from "react-media-hook";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import "./index.less";
import {
  CheckCircleOutlined,
  LockOutlined,
  RightOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import GoToTop from "../../components/GoToTop";
import { HashLink } from "react-router-hash-link";

import { useSelector } from "react-redux";
import { getHomeSectionsContent } from "../../store/strapi/gamaleasing";

const Home = () => {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const homeContent = useSelector(getHomeSectionsContent);
  const cardList = homeContent[0]?.Card;
  const CarouselImages = [
    {
      imagen: FurgonPasajeros,
      title: "Furgón pasajeros",
      description:
        "Es el modelo ideal para transportes de pasajeros, siendo un vehículo que mantiene a los conductores seguros, alertas y cómodos. Se encuentra presente en industrias de: Turismo, Transporte y Comercial.",
    },
    {
      imagen: FurgonGrande,
      title: "Furgón grande",
      description:
        "El furgón grande es el modelo utilizado para transportes de cargas livianas de volumen, presente en industrias tales como: Courier, Alimentos, Comercio, Servicios y Transporte.",
    },
    {
      imagen: FurgonPequeno,
      title: "Furgón pequeño",
      description:
        "El furgón pequeño es el modelo ideal para transportes de cargas livianas, presente en industrias tales como: Telecomunicaciones, Transporte, Comercio, Servicios, Courier y Seguridad.",
    },
    {
      imagen: Camion,
      title: "Camión",
      description:
        "La linea de camiones ¾ son eficientes y poseen buena capacidad de transporte de cargas semi pesadas o de volumen con equipamiento especial según los requerimientos del Cliente. Se encuentra presentes en industrias de: Transporte, Comercio, Alimentos y Servicios.",
    },
    {
      imagen: CamionPequeno,
      title: "Camión pequeño",
      description:
        "Los camiones pequeños brindan la mayor confiabilidad y agilidad al ocuparlo, con equipamiento especial según los requerimientos del Cliente. Estos modelos son utilizados para transportes de carga liviana. Se encuentran presente en industrias de Transporte, Comercio, Alimentos y Servicios.",
    },
    {
      imagen: Camioneta,
      title: "Camioneta",
      description:
        "Modelos 4x2 y 4x4, con equipamiento especial según los requerimientos del Cliente y desplazamientos de persona, es el modelo ideal para cargas liviana, presentes en las industria Minera, Forestal, Pesquera, Construcción y Sanitaria.",
    },
  ];

  const BenefitCards = [
    {
      icon: <ToolOutlined />,
      img: cardList ? cardList[0]?.Icon?.url : null,
      title: cardList ? cardList[0]?.Title : "Optimiza tus recursos",
      description: cardList
        ? cardList[0].Paragraph
        : "Puedes invertir en otros insumos o áreas de trabajo propios al no tener que hacerte cargo directamente de tu flota.",
    },
    {
      icon: <CheckCircleOutlined />,
      img: cardList ? cardList[1]?.Icon?.url : null,
      title: cardList ? cardList[1]?.Title : "Renovación periódica",
      description: cardList
        ? cardList[1]?.Paragraph
        : "Tendrás a disposición el recambio de los automóviles, evitando así la obsolescencia e integrando tecnologías de última categoría que den respuesta a las nuevas necesidades.",
    },
    {
      icon: <LockOutlined />,
      img: cardList ? cardList[2]?.Icon?.url : null,
      title: cardList ? cardList[2]?.Title : "Seguridad y tranquilidad",
      description: cardList
        ? cardList[2]?.Paragraph
        : "Nuestro equipo de profesionales gestionará los trámites que representan gastos en la flota arrendada y tu empresa podrá dedicarse exclusivamente a sus operaciones.",
    },
  ];

  const BenefitCardItems = BenefitCards.map(
    ({ img, icon, title, description }) => (
      <BenefitCard icon={img ? null : icon} title={title} imagen={img}>
        {description}
      </BenefitCard>
    )
  );

  const fleetCardItems = CarouselImages.map(
    ({ icon, title, description, imagen }) => (
      <BenefitCard key={title} icon={icon} title={title} imagen={imagen}>
        {description}
      </BenefitCard>
    )
  );

  const strapiCardItems = homeContent && homeContent[2]?.Card?.map(
    (vehicles) => (
      <BenefitCard key={vehicles.Title}  title={vehicles.Title} imagen={vehicles?.Icon?.url}>
        {vehicles.Paragraph}
      </BenefitCard>
    )
  );

  const options = {
    rewind: true,
    type: "loop",
    autoplay: true,
  };

  return (
    <div>
      <GoToTop />
      <HomeLayout >
        <div className="container-home">
          <section id="beneficios">
            <Row className="container-home-benefits" justify="space-between">
              <Col lg={10}>
                <Image
                  onerror='this.onerror = null; this.src="./oops.gif"'
                  src={
                    homeContent !== null
                      ? homeContent[0]?.Image?.url
                      : BannerCamiones
                  }
                  preview={false}
                />
              </Col>
              <Divider type="vertical" className="benefits-divider" />
              <Col className="benefits-description" lg={13}>
                <h3>
                  {homeContent?.length === 0 || homeContent === null
                    ? "Beneficios para tu empresa"
                    : homeContent && homeContent[0]?.Title}
                </h3>
                <p
                  style={{
                    maxWidth: "800px",
                    width: biggerThan900 ? "" : "300px",
                    fontSize: "18px",
                    lineHeight: "32px",
                  }}
                >
                  {homeContent || homeContent === null
                    ? homeContent && homeContent[0]?.Paragraph
                    : "Gama te ofrece soluciones a la medida de las necesidades de tu empresa y rubro. Tenemos a tu disposición la flota que necesitas para cubrir cada requerimiento de tu negocio, desde furgones de carga y de pasajeros, hasta camionetas y camiones."}
                </p>
              </Col>
            </Row>

            <div className="benefits-cards">
              {biggerThan900 ? (
                BenefitCardItems
              ) : (
                <Carousel>{BenefitCardItems}</Carousel>
              )}
            </div>
          </section>

          <section id="about-us">
            <div className="container-home-about-us">
              <div className="about-description">
                <Divider />
                <Row>
                  <Col xs={24} lg={14} className="about-intro">
                    <h3>
                      {homeContent?.length === 0 || homeContent === null
                        ? "¿ Qué es Leasing Operativo ?"
                        : homeContent && homeContent[1]?.Title}
                    </h3>
                    <p>
                      {homeContent?.length === 0 || homeContent === null
                        ? `El Leasing Operativo consiste en el arriendo de flotas de vehículos con equipamiento específico para cada rubro, según las necesidades de cada empresa y por un periodo de mediano o largo plazo. Este servicio incluye mantenciones preventivas y correctivas, documentación, permisos, seguros y vehículos de reemplazo, asegurando la continuidad operacional de su empresa y una flota siempre disponible por una cuota mensual, según solicitud de cada cliente.

                      Además, Gama se encarga de la gestión y administración de tu
                      flota, de manera que puedas ocuparte de lo más importante:
                      tu negocio.`
                        : homeContent && homeContent[1]?.Paragraph}
                    </p>
                  </Col>
                  <Col xs={24} lg={10}>
                    <Row justify="end">
                      <Image
                        src={
                          homeContent
                            ? homeContent[1]?.Image?.url
                            : QueHacemos
                        }
                        preview={false}
                      />
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </section>

          {/* <section id="our-services">
          <div className="container-our-services">
            <div className="our-services-title">
              <h3>Nuestros servicios</h3>
              <Divider type="vertical" />
            </div>
            <div className="our-services-cards">
              {biggerThan900 ? (
                <Row className="our-service-row">
                  <Col style={{ marginRight: "20px" }} span={7}>
                    {ServiceCardItems.slice(0, 3)}
                  </Col>
                  <Col style={{ marginRight: "20px" }} span={7}>
                    {ServiceCardItems.slice(3, 6)}
                  </Col>
                  <Col style={{ marginRight: "20px" }} span={7}>
                    {ServiceCardItems.slice(6, 8)}
                  </Col>
                </Row>
              ) : (
                <Carousel>
                  <div className="item">{ServiceCardItems.slice(0, 4)}</div>
                  <div className="item">{ServiceCardItems.slice(4, 8)}</div>
                </Carousel>
              )}
            </div>
          </div>
        </section> */}

          <section id="flota">
            <div className="container-carousel-section">
              <div className="carousel-header">
                <h3>
                  {homeContent?.length === 0 || homeContent === null
                    ? "¿ Tienes una empresa y necesitas un auto ?"
                    : homeContent && homeContent[2]?.Title}
                </h3>
                <p>
                  {homeContent?.length === 0 || homeContent === null
                    ? "Si quieres disfrutar de todas las ventajas que te da tener un auto en suscripción echa un vistazo a nuestra flota."
                    : homeContent && homeContent[2]?.Paragraph}
                </p>
              </div>

              {biggerThan900 ? (
                <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                  <Splide
                    hasTrack={false}
                    options={options}
                    style={{
                      width: "100%",
                    }}
                    aria-label="My Favorite Images"
                  >
                    <div className="splide__arrows">
                      <button className="splide__arrow splide__arrow--prev">
                        <span
                          style={{
                            backgroundColor: "#FF3200",
                            color: "#FF3200",
                            cursor: "pointer",
                            padding: "7px 7px 7px 7px",
                            borderRadius: "100%",
                            border: "1px solid #ff3200",
                          }}
                        >
                          <RightOutlined />
                        </span>
                      </button>
                      <button className="splide__arrow splide__arrow--next">
                        <span
                          style={{
                            backgroundColor: "#FF3200",
                            color: "#FF3200",
                            cursor: "pointer",
                            padding: "7px 7px 7px 7px",
                            borderRadius: "100%",
                            border: "1px solid #ff3200",
                          }}
                        >
                          <RightOutlined />
                        </span>
                      </button>
                    </div>

                    <SplideTrack>
                      {homeContent?.length === 0 || homeContent === null
                        ? CarouselImages?.map((vehicle) => (
                            <SplideSlide
                              key={vehicle.title}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",

                                  alignItems: "center",
                                  // width: "50%",
                                }}
                              >
                                {biggerThan900 ? (
                                  <img
                                    style={{
                                      width: "40%",
                                      height: "auto",
                                      marginLeft: "150px",
                                    }}
                                    src={vehicle.imagen}
                                    alt="truck"
                                  />
                                ) : null}
                                <div
                                  style={{
                                    maxWidth: "575px",
                                    paddingLeft: biggerThan900
                                      ? "110px"
                                      : "0px",
                                  }}
                                >
                                  <h5
                                    style={{
                                      fontSize: "24px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    {vehicle?.title}
                                  </h5>
                                  <p style={{ lineHeight: "32px" }}>
                                    {vehicle?.description}
                                  </p>
                                  <HashLink to="/flota#top">
                                    <Button>Mostrar todo</Button>
                                  </HashLink>
                                </div>
                              </div>
                            </SplideSlide>
                          ))
                        :  homeContent && homeContent[2]?.Card?.map((vehicle) => (
                            <SplideSlide
                              key={vehicle.Title}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",

                                  alignItems: "center",
                                  // width: "50%",
                                }}
                              >
                                {biggerThan900 ? (
                                  <img
                                    style={{
                                      width: "40%",
                                      height: "auto",
                                      marginLeft: "150px",
                                    }}
                                    src={vehicle?.Icon?.url}
                                    alt="truck"
                                  />
                                ) : null}
                                <div
                                  style={{
                                    maxWidth: "575px",
                                    paddingLeft: biggerThan900
                                      ? "110px"
                                      : "0px",
                                  }}
                                >
                                  <h5
                                    style={{
                                      fontSize: "24px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    {vehicle?.Title}
                                  </h5>
                                  <p style={{ lineHeight: "32px" }}>
                                    {vehicle?.Paragraph}
                                  </p>
                                  <HashLink
                                    to={
                                      vehicle.Hreference
                                        ? vehicle.Hreference
                                        : "/flota#top"
                                    }
                                  >
                                    <Button>{vehicle?.Button.Label}</Button>
                                  </HashLink>
                                </div>
                              </div>
                            </SplideSlide>
                          ))}
                    </SplideTrack>
                  </Splide>
                </div>
              ) : (
                <div className="benefits-cards">
                  {homeContent?.length === 0 || homeContent === null
                        ?
                        biggerThan900 ? (
                          fleetCardItems
                          ) : (
                            <Carousel>{fleetCardItems}</Carousel>
                            )
                            : 
                            biggerThan900 ? (
                              strapiCardItems
                              ) : (
                                <Carousel>{strapiCardItems}</Carousel>
                                )
                            }
                </div>
              )}
            </div>
          </section>
        </div>
      </HomeLayout>
      
</div>
  );
};

export default Home;
