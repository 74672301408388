import { Col, Image, Layout, Row} from "antd";
import React, { useEffect, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import GoToTop from "../../components/GoToTop";
import Burger from "../../components/layouts/HomeLayout/Burger";
import { useScroll } from "../../utils/useScroll";
import Logo from "../../assets/img/logo-mobility.webp";
import Footer from "../../components/layouts/HomeLayout/Footer";

function PrivacyPolices() {
  const [isHome, setIsHome] = useState(false);
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (window.location.pathname === "/avisos-legales") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);

  const burguerData = [
    {
      title: "Sucursales",
      link: "/sucursales",
    },
    {
      title: "Nuestros servicios",
      link: "/mobility#servicios",
    },
    {
      title: "Quienes Somos",
      link: "/mobility#nosotros",
    },
    { title: "Beneficios", link: "/mobility#beneficios" },
    { title: "Publicaciones", link: "/mobility#publicaciones" },
  ];

  const { scrollDirection } = useScroll();

  const styles = {
    active: {
      // visibility: "visible",
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      // visibility: "hidden",
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };
  return (
    <div>
      <GoToTop />
      <Layout>
        <section
          className="home-banner-container"
          id="header"
          style={{
            height: `12vh`,
          }}
        >
          <Row className="header-contain">
            <Col
              id="navbar"
              style={
                scrollDirection === "down" || !scrollDirection
                  ? styles.active
                  : styles.hidden
              }
            >
              <nav className="home-nav-container">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      style={{
                        color:
                          window.location.pathname === "/mobility"
                            ? "#FF3200"
                            : "white",
                      }}
                      href={"https://gamamobility.cl/"}
                      rel="noreferrer"
                    >
                      GAMA MOBILITY
                    </a>
                  </li>
                  <li>
                    <Link
                      style={{
                        color:
                          window.location.pathname === "" ? "#FF3200" : "white",
                      }}
                      to={"/"}
                    >
                      GAMA LEASING
                    </Link>
                  </li>

                  <li>
                    <a href={"https://www.gamarent.cl/"}>RENT A CAR</a>
                  </li>
                  <li>
                    <a href={"https://www.tripp.cl/"}>TRIPP</a>
                  </li>
                  <li>
                    <Link to={"/usados"}>GAMA USADOS</Link>
                  </li>
                </ul>
              </nav>
              <header>
                <div className="home-nav-items">
                  <div className="home-nav-logo">
                    <Link to={"/"}>
                      <Image preview={false} src={Logo} width={120} />
                    </Link>
                  </div>
                  <div className="group-nav-items">
                    <div
                      className="nav-container-buttons"
                      style={{
                        transition: "all ease-out 1s;",
                        opacity: `${visible ? "0" : "1"}`,
                        pointerEvents: `${visible ? "none" : "auto"}`,
                      }}
                    >
                      <Link to="/contacto-mobility">
                        <Button
                          onClick={() => {}}
                          className="gama-outline talk-button"
                        >
                          Conversemos
                        </Button>
                      </Link>
                    </div>
                    <div className="container-menu">
                      {biggerThan900 ? (
                        <div className="container-navlinks">
                          {!isHome ? (
                            <>
                              <a smooth href="/sucursales">
                                Sucursales
                              </a>
                              <a smooth href="#servicios">
                                Nuestros Servicios
                              </a>
                              <a smooth href="#nosotros">
                                Quienes Somos
                              </a>
                              <a smooth href="#beneficios">
                                Beneficios
                              </a>
                              <a smooth href="#publicaciones">
                                Publicaciones
                              </a>
                            </>
                          ) : (
                            <>
                              <Link to="/sucursales">Sucursales</Link>
                              <a smooth href="/mobility#servicios">
                                Nuestros Servicios
                              </a>
                              <a smooth href="/mobility#nosotros">
                                Quienes Somos
                              </a>
                              <a href="/mobility#beneficios">Beneficios</a>
                              <a href="/mobility#publicaciones">Publicaciones</a>
                            </>
                          )}
                        </div>
                      ) : (
                        <Burger
                          data={burguerData}
                          boton={false}
                          modal={visible}
                          onClose={() => {
                            setVisible(!visible);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </header>
            </Col>
          </Row>
        </section>
      </Layout>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
          fontSize: "32px",
          fontWeight: "600",
        }}
      >
        <Col style={{ fontSize: biggerThan900 ? "32px" : "21px" }}>
          POLÍTICAS DE PRIVACIDAD
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: biggerThan900 ? "50%" : "90%",
          }}
        >
          <Col
            style={{
              textAlign: "left",
              width: "980px",
              color: "#FF3200",
              fontWeight: "600",
              fontSize: "15px",
              marginBottom: "5px",
            }}
          >
            1. Para nuestros clientes
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "15px",
              fontSize: "16px",
            }}
          >
            El derecho a la confidencialidad y protección de sus datos
            personales es una de las principales preocupaciones de nuestra
            empresa. En este documento se refleja la política actual de Gama
            Leasing Operativo Spa. (en adelante, la “Empresa”) en esta materia.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              fontSize: "16px",
            }}
          >
            Nuestra labor principal es ofrecer servicios de movilidad para
            empresas y personas.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "980px",
              color: "#FF3200",
              fontWeight: "600",
              fontSize: "15px",
              marginBottom: "5px",
              marginTop: "5px",
            }}
          >
            2. Aplicación de Normativas
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "15px",
              fontSize: "16px",
            }}
          >
            Mediante la presente Política de Privacidad y Confidencialidad, esta
            se realiza bajo la normativa legal aplicable a los servicios de
            movilidad que ofrecemos, regulados por la Ley N° 19.496 sobre
            Protección a los Derechos de los Consumidores, Ley N° 19.628 sobre
            La Protección de la Vida Privada, Ley N° 20.575 que establece el
            principio y finalidad de los datos personales.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "15px",
              fontSize: "16px",
            }}
          >
            La Política de Confidencialidad hace referencia a los datos de
            carácter personal que obtenemos de nuestros clientes o personas
            interesadas en nuestra empresa y nuestros servicios de arriendo de
            vehículos.
          </Col>

          <Col
            style={{
              textAlign: "left",
              width: "980px",
              color: "#FF3200",
              fontWeight: "600",
              fontSize: "15px",
              marginBottom: "5px",
            }}
          >
            3. Recopilación de datos
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "15px",
              fontSize: "16px",
            }}
          >
            Las Políticas de Privacidad se refieren a ciertos datos de carácter
            personal recaudados por la empresa referente a sus clientes, como,
            por ejemplo:
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "5px",
              fontSize: "16px",
            }}
          >
            Datos proporcionados por los clientes, como su nombre, dirección,
            empresa (para las que trabajan), números de teléfono, direcciones de
            correo electrónico, fecha y lugar de nacimiento, número de licencia
            de conducir, RUT, información relativa a las tarjetas de crédito que
            utilizan.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "5px",
              fontSize: "16px",
            }}
          >
            Además de la información que recibimos de las diferentes agencias de
            información sobre consumidores, como su solvencia y su historial de
            crédito.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "5px",
              fontSize: "16px",
            }}
          >
            Nuestra fuente de datos personales suministrados por clientes,
            incluye nuestra web https://www.gamaleasing.cl así como diferentes
            medios, como Redes Sociales, Landing Page y medios promocionales
            usados por la empresa.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "5px",
              fontSize: "16px",
            }}
          >
            No son objeto de tratamiento los datos sensibles de los clientes,
            señalados en el artículo 2 letra g) de la Ley N° 19.628 sobre
            Protección de la Vida Privada. Sin perjuicio de ello, y tal como
            establece la normativa, eventualmente para la prestación del
            servicio se solicitará información relativa a su salud. La empresa
            no divulga los datos de carácter personal, salvo en los casos
            autorizados por la normativa.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "980px",
              color: "#FF3200",
              fontWeight: "600",
              fontSize: "15px",
              marginBottom: "5px",
            }}
          >
            4. Empleo de “cookies” y demás tecnologías de seguimiento
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "15px",
              fontSize: "16px",
            }}
          >
            La empresa emplea cookies para hacer un seguimiento de los Usuarios
            y los clientes, como para facilitar el uso de los servicios
            ofrecidos a través de sus sitios webs o de los mensajes de correo
            electrónico de carácter comercial enviados en formato HTML.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "15px",
              fontSize: "16px",
            }}
          >
            La empresa almacena cookies para poder ofrecer una experiencia más
            interactiva en su sitio web y facilitar el uso de los servicios que
            ofrece a los clientes, basada en las acciones del Usuario. El
            Usuario puede, en cualquier momento, eliminar las cookies
            almacenadas, configurar el navegador para que solicite aprobación
            antes de almacenar cookies o directamente evitar que se almacenen
            cookies.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              marginBottom: "15px",
              fontSize: "16px",
            }}
          >
            Además, nuestros proveedores de publicidad por correo electrónico y
            en línea, pueden utilizar etiquetas píxel al emplear nuestros sitios
            web y nuestras comunicaciones por correo electrónico.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "980px",
              color: "#FF3200",
              fontWeight: "600",
              fontSize: "15px",
              marginBottom: "5px",
            }}
          >
            5. Conservación de datos
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            La empresa conserva los datos de carácter personal de sus clientes y
            Usuarios durante el tiempo que permite la legislación del país. En
            la actualidad, la mayor parte de dichos datos se almacenan en
            Santiago de Chile.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            Los Usuarios en todo momento pueden declinar del uso de sus datos
            personales, y dispondrán siempre de los derechos de aclaración,
            rectificación, cancelación y oposición, además de todos los otros
            derechos que confiere la Ley N° 19.628 sobre Protección de la Vida
            Privada.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "980px",
              color: "#FF3200",
              fontWeight: "600",
              fontSize: "15px",
              marginBottom: "5px",
            }}
          >
            6. Seguridad de los datos
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              fontSize: "16px",
              marginBottom: "15px",
            }}
          >
            Gama cumple con toda la normativa que puede ser aplicada en medidas
            de seguridad a la información personal de nuestros clientes, esto a
            través de mecanismos de protección, tanto físicos y electrónicos,
            que tienen como objetivo proteger sus datos personales y evitar su
            pérdida, utilización ilegal, divulgación u alteraciones no
            autorizados.
          </Col>
          <Col
            style={{
              textAlign: "left",
              width: "820px",
              lineHeight: "21px",
              fontSize: "16px",
              marginBottom: "70px",
            }}
          >
            El acceso a la información personal de los clientes está autorizado
            únicamente a aquellos empleados que necesitan conocer aquellos datos
            para suministrarle o facilitarle el servicio contrato o requerido.
            De esta misma manera está totalmente penalizado el uso indebido de
            la información personal de nuestros clientes por parte de nuestros
            empleados, lo que puede acarrear sanciones importantes por parte de
            la empresa.
          </Col>
        </Row>
      </Row>
      <Footer />
    </div>
  );
}

export default PrivacyPolices;
