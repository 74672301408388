import { Col, Image, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Burger from "../../components/layouts/HomeLayout/Burger";
import MobilityBanner from "../../assets/img/mobilitybanner.webp";
import { useScroll } from "../../utils/useScroll";
import { useMediaPredicate } from "react-media-hook";
import Logo from "../../assets/img/logo-mobility.webp";
import Button from "../../components/Button";
import Logo1 from "../../assets/img/image 73.webp";
import Logo2 from "../../assets/img/image 74.webp";
import Logo3 from "../../assets/img/image 75.webp";
import Camioneta from "../../assets/img/kisspng-2018.webp";
import Compromise1 from "../../assets/img/001-about-noun_fast_359305-1-1.webp";
import Compromise2 from "../../assets/img/002-about-noun_success_1195075-1-1.webp";
import Compromise3 from "../../assets/img/003-about-noun_efficiency_3356873-1-1.webp";
import Compromise4 from "../../assets/img/004-about-noun_Idea_2053564-1-1.webp";
import Compromise5 from "../../assets/img/005-about-noun_employees_2072725-1-1.webp";
import Compromise6 from "../../assets/img/006-about-noun_Hire-Car_121692-1-1.webp";
import Mobility from "../../assets/img/about-002.webp";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Footer from "../../components/layouts/HomeLayout/Footer";
import GoToTop from "../../components/GoToTop";

function MobilityUS() {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const biggerThan1300 = useMediaPredicate("(min-width: 1420px)");

  const [visible, setVisible] = useState(false);

  const [isHome, setIsHome] = useState();

  useEffect(() => {
    if (window.location.pathname === "/mobility-nosotros") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);

  const styles = {
    active: {
      // visibility: "visible",
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      // visibility: "hidden",
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };

  const burguerData = [
    {
      title: "Sucursales",
      link: "/sucursales",
    },
    {
      title: "Nuestros servicios",
      link: "/mobility#servicios",
    },
    {
      title: "Quienes Somos",
      link: "/mobility#nosotros",
    },
    { title: "Beneficios", link: "/mobility#beneficios" },
    { title: "Publicaciones", link: "/mobility#publicaciones" },
  ];

  const compromises = [
    {
      title: "Rapidez y calidad de servicio",
      description:
        "Entregamos un servicio de calidad, enfocado en proporcionar soluciones rápidas a las necesidades de Movilidad de nuestros clientes.",
      icon: Compromise1,
    },
    {
      title: "Satisfacción de clientes y proveedores",
      description:
        "Aseguramos la satisfacción de nuestros clientes, tanto durante la entrega de nuestros servicios como durante el proceso de post venta.",
      icon: Compromise2,
    },
    {
      title: "eficiencia y continuidad operacional",
      description:
        "Aseguramos la continuidad operacional de tu flota y brindamos un servicio eficaz, 100% Movilidad a todos nuestros clientes, para que tú o tu empresa siempre estén en movimiento.",
      icon: Compromise3,
    },
    {
      title: "Soluciones de movilidad flexibles y personalizadas",
      description:
        "Contamos con soluciones hechas a medida para satisfacer cada uno de los requerimientos y necesidades de nuestros clientes, ya sea en arriendo de flotas para empresas o de autos para particulares.",
      icon: Compromise4,
    },
    {
      title: "Experiencia en el rubro",
      description:
        "Nuestro equipo especializado y amplia experiencia en el rubro nos avalan como uno de los mejores operadores en Movilidad de Chile.",
      icon: Compromise5,
    },
    {
      title: "Innovación",
      description:
        "Contamos con opciones de Leasing Operativo, Renting, Rent a Car y venta de Autos Usados y Seminuevos, entregando una variada gama de soluciones de Movilidad.",
      icon: Compromise6,
    },
  ];

  const { scrollDirection } = useScroll();
  return (
    <div>
      <GoToTop />
      <Layout>
        <section
          className="home-banner-container"
          id="header"
          style={{
            height: `80vh`,
          }}
        >
          <Row
            className="header-contain"
            style={{
              backgroundImage: `url(${MobilityBanner})`,
            }}
          >
            <Col
              id="navbar"
              style={
                scrollDirection === "down" || !scrollDirection
                  ? styles.active
                  : styles.hidden
              }
            >
              <nav className="home-nav-container">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href={"https://gamamobility.cl/"}
                      rel="noreferrer"
                    >
                      GAMA MOBILITY
                    </a>
                  </li>
                  <li>
                    <Link
                      style={{
                        color:
                          window.location.pathname === "" ? "#FF3200" : "white",
                      }}
                      to={"/"}
                    >
                      GAMA LEASING
                    </Link>
                  </li>

                  <li>
                    <a href={"https://www.gamarent.cl/"}>RENT A CAR</a>
                  </li>
                  <li>
                    <a href={"https://www.tripp.cl/"}>TRIPP</a>
                  </li>
                  <li>
                    <Link to={"/usados"}>GAMA USADOS</Link>
                  </li>
                </ul>
              </nav>
              <header>
                <div className="home-nav-items">
                  <div className="home-nav-logo">
                    <Link to={"/"}>
                      <Image
                        preview={false}
                        src={Logo}
                        style={{ width: "130px" }}
                      />
                    </Link>
                  </div>
                  <div className="group-nav-items">
                    <div
                      className="nav-container-buttons"
                      style={{
                        transition: "all ease-out 1s;",
                        opacity: `${visible ? "0" : "1"}`,
                        pointerEvents: `${visible ? "none" : "auto"}`,
                      }}
                    >
                      <Link to="/contacto-mobility">
                        <Button
                          onClick={() => {}}
                          className="gama-outline talk-button"
                        >
                          Conversemos
                        </Button>
                      </Link>
                    </div>
                    <div className="container-menu">
                      {biggerThan900 ? (
                        <div className="container-navlinks">
                          {isHome ? (
                            <>
                              <a smooth href="/sucursales">
                                Sucursales
                              </a>
                              <a smooth href="#servicios">
                                Nuestros Servicios
                              </a>
                              <a smooth href="#nosotros">
                                Quienes Somos
                              </a>
                              <a smooth href="#beneficios">
                                Beneficios
                              </a>
                              <a smooth href="#publicaciones">
                                Publicaciones
                              </a>
                            </>
                          ) : (
                            <>
                              <Link to="/sucursales">Sucursales</Link>
                              <Link to="/mobility#servicios">Nuestros Servicios</Link>
                              <Link to="/mobility#nosotros">Quienes Somos</Link>
                              <Link to="mobility#beneficios">Beneficios</Link>
                              <Link to="/mobility#publicaciones">Publicaciones</Link>
                            </>
                          )}
                        </div>
                      ) : (
                        <Burger
                          data={burguerData}
                          boton={false}
                          modal={visible}
                          onClose={() => {
                            setVisible(!visible);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </header>
            </Col>
          </Row>
          <div className="banner-title-container">
            <Row>
              <Row
                id="beneficios"
                justify="center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "50px",
                }}
              >
                <Col
                  style={{
                    fontSize: "40px",
                    fontWeight: "700",
                    marginBottom: "16px",
                    color: "white",
                  }}
                >
                  Quienes Somos
                </Col>
                <Col
                  style={{
                    width: "500px",
                    lineHeight: "20px",
                    marginBottom: "26px",
                    color: "white",
                  }}
                >
                  Gama es una Sociedad compuesta por Empresas Penta, Grupo
                  Indumotora y Eurofrance. Nace para satisfacer la demanda del
                  mercado en el arrendamiento de flotas de vehículos para
                  empresas o de autos para particulares en el país, a través de
                  los servicios de Gama Leasing, Tripp, Gama Rent a Car y Gama
                  Usados.
                </Col>
                <Row align="middle">
                  <img
                    style={{
                      width: "102px",
                      height: "50px",
                      marginRight: "15px",
                    }}
                    src={Logo2}
                  />
                  <img
                    style={{
                      width: "165px",
                      height: "18px",
                      marginRight: "15px",
                    }}
                    src={Logo1}
                  />
                  <img
                    style={{
                      width: "158px",
                      height: "43px",
                      marginRight: "15px",
                    }}
                    src={Logo3}
                  />
                </Row>
              </Row>
              <Row>
                <img
                  style={{
                    marginTop: "80px",
                    marginLeft: "26px",
                    width: "auto",
                    height: "auto",
                  }}
                  src={Camioneta}
                />
              </Row>
            </Row>
          </div>
        </section>
      </Layout>
      <Row justify="center">
        <Col style={{ fontSize: "50px", fontWeight: "600", marginTop: "78px" }}>
          Nuestro Compromiso
        </Col>
      </Row>
      <Row
        justify="center"
        style={{ display: "flex", marginTop: "70px", marginLeft: "200px" }}
      >
        {compromises.map((compromise) => (
          <Row key={compromise.title}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              width: biggerThan1300 ? "500px" : "400px",
            }}
          >
            <Col>
              <img style={{ marginBottom: "15px" }} src={compromise.icon} />
            </Col>
            <Col
              style={{
                fontSize: "20px",
                fontWeight: "600",
                width: "250px",
              }}
            >
              {compromise.title}
            </Col>
            <Col
              style={{
                fontSize: "15px",
                fontWeight: "400",
                width: "280px",
                marginBottom: "50px",
              }}
            >
              {compromise.description}
            </Col>
          </Row>
        ))}
      </Row>
      <Row justify="center">
        <Col
          style={{
            fontSize: "50px",
            fontWeight: "600",
            marginTop: "78px",
            marginBottom: "70px",
          }}
        >
          Pioneros en soluciones de Movilidad
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "150px",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginRight: "50px",
          }}
        >
          <Col
            style={{
              width: "594px",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "400",
              color: "black",
              marginBottom: "80px",
            }}
          >
            En Gama buscamos entregar soluciones en Movilidad para empresas y
            personas de forma eficiente, innovadora y a la medida de las
            necesidades de nuestros clientes.
          </Col>
          <Row
            align="middle"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Row>
              <Col>
                <QuestionCircleOutlined
                  style={{
                    fontSize: "30px",
                    marginRight: "10px",
                    color: " #FF3200",
                  }}
                />
              </Col>
              <Col style={{ marginRight: "25px" }}>Compromiso</Col>
              <Col
                style={{
                  width: "450px",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                Comprometidos a entregar soluciones de movilidad de la más alta
                calidad y eficiencia.
              </Col>
            </Row>
          </Row>
          <Row style={{ display: "flex" }}>
            <Row>
              <Col>
                <QuestionCircleOutlined
                  style={{
                    fontSize: "30px",
                    marginRight: "10px",
                    color: " #FF3200",
                  }}
                />
              </Col>
              <Col style={{ marginRight: "25px" }}>Versalitidad</Col>
              <Col
                style={{
                  width: "450px",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                Entregamos una oferta variada de opciones para las distintas
                necesidades de nuestros clientes.
              </Col>
            </Row>
          </Row>
          <Row style={{ display: "flex" }}>
            <Row>
              <Col>
                <QuestionCircleOutlined
                  style={{
                    fontSize: "30px",
                    marginRight: "10px",
                    color: " #FF3200",
                  }}
                />
              </Col>
              <Col style={{ marginRight: "25px", minWidth: "70px" }}>
                Respeto
              </Col>
              <Col
                style={{
                  width: "450px",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                Basamos las relaciones con nuestros proveedores, aliados y
                clientes en la transparencia y respeto.
              </Col>
            </Row>
          </Row>
        </Row>
        <img style={{ marginTop: "40px" }} src={Mobility} />
      </Row>
      <Footer logo={Logo} />
    </div>
  );
}

export default MobilityUS;
