import axios from "axios";

import { STRAPI_API_URL } from "../../url";

const BASE_URL = STRAPI_API_URL;

export const getGamaUsadosMainContent = async () => {
  const { data: navbar } = await axios.get(
    `${BASE_URL}/gu-mains/x0czmwi67rrjxa4wouj870mu?populate[0]=Navbar.Logo&populate[1]=Navbar.TopLinks&populate[2]=Navbar.NavLinks&populate[3]=Navbar.NavButton`
  );
  const { data: header } = await axios.get(
    `${BASE_URL}/gu-mains/x0czmwi67rrjxa4wouj870mu?populate[0]=Header.BannerImage&populate[1]=Header.Title&populate[2]=Header.Button`
  );
  const { data: sections } = await axios.get(
    `${BASE_URL}/gu-mains/x0czmwi67rrjxa4wouj870mu?populate[0]=Sections.Image&populate[1]=Sections.Card.Icon&populate[2]=Sections.Button`
  );

  return {
    navbar: navbar?.data,
    header: header?.data,
    sections: sections?.data,
    // footer: footer.data[0].attributes,
  };
};

export const getGamaUsadosContactContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gu-mains/aegfmn7p19a7wcqolls9he7k?populate[0]=Header.BannerImage&populate[1]=Header.Title&populate[2]=Header.Button`
  );
  const { data: sider } = await axios.get(
    `${BASE_URL}/gu-mains/aegfmn7p19a7wcqolls9he7k?populate[0]=Sections.Image&populate[1]=Sections.Card.Icon&populate[2]=Sections.Button`
  );
  return {
    header: header?.data?.Header,
    sections: sider?.data?.Sections,
  };
};
