import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getFQAContent,
  getGamaBranchesContent,
  getGamaContactContent,
  getGamaFleetContent,
  getGamaMainContent,
  getPaymentContent,
  getPostsContent,
  getSinisterContent,
} from "../../../requests/strapi/gamaleasing";

export const getGamaLeasingHomeContent = createAsyncThunk(
  "gamaleasing/getGamaLeasingHomeContent",
  async (_, { rejectWithValue }) => {
    try {
      const { header, sections, footer, navbar } = await getGamaMainContent();
      const { Logo, NavButton, NavLinks, TopLinks } = navbar;
      const { BannerImage, Button, Title} = header;

      return {
        navbar: {
          topLinks: TopLinks,
          logoUrl: Logo?.url,
          navLinks: NavLinks,
          navButtons: NavButton,
        },
        header: {
          bannerImage: BannerImage[0],
          bannerButton: Button[0],
          title: Title.Title,
          detail: Title.Detail,
        },
        sections,
        footer
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const getGamaLeasingFleetContent = createAsyncThunk(
  "gamaleasing/getGamaLeasingFleetContent",
  async (_, { rejectWithValue }) => {
    try {
      const { header, gallery } = await getGamaFleetContent();
      const { BannerImage, Title } = header.Header;

      return {
        header: {
          bannerImage: BannerImage,
          title: Title,
        },
        gallery,
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const getGamaLeasingContactContent = createAsyncThunk(
  "gamaleasing/getGamaLeasingContactContent",
  async (_, { rejectWithValue }) => {
    try {
      const { header, sections } = await getGamaContactContent();
      const { BannerImage, Title } = header.Header;
      return {
        header: {
          bannerImage: BannerImage,
          title: Title,
        },
        sections,
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const getGamaLeasingBranchesContent = createAsyncThunk(
  "gamaleasing/getGamaLeasingBranchesContent",
  async (_, { rejectWithValue }) => {
    try {
      const { header, branches } = await getGamaBranchesContent();
      const { BannerImage, Title } = header.Header;
      return {
        header: {
          bannerImage: BannerImage,
          title: Title,
        },
        branches: {
          Title01: branches[0].Title,
          Title02: branches[1].Title,
          AssociatedWorkshops: branches[1].Card,
          GamaWorkshops: branches[0].Card,
        },
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const getGamaLeasingSinisterContent = createAsyncThunk(
  "gamaleasing/getGamaLeasingSinisterContent",
  async (_, { rejectWithValue }) => {
    try {
      const { header, sections } = await getSinisterContent();
      const { BannerImage, Title } = header.Header;

      return {
        header: {
          bannerImage: BannerImage,
          title: Title,
        },
        sections: { ...sections.Sections },
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const getGamaLeasingPaymentContent = createAsyncThunk(
  "gamaleasing/getGamaLeasingPaymentContent",
  async (_, { rejectWithValue }) => {
    try {
      const { header, sections } = await getPaymentContent();
      const { BannerImage, Title } = header.Header;

      return {
        header: {
          bannerImage: BannerImage,
          title: Title,
        },
        sections: { ...sections.Sections },
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const getGamaLeasingFAQContent = createAsyncThunk(
  "gamaleasing/getGamaLeasingFAQContent",
  async (_, { rejectWithValue }) => {
    try {
      const { header, sections } = await getFQAContent();
      const { BannerImage, Title } = header.Header;

      return {
        header: {
          bannerImage: BannerImage,
          title: Title,
        },
        sections: { ...sections.Sections },
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);

export const getGamaLeasingPostsContent = createAsyncThunk(
  "gamaleasing/getGamaLeasingPostsContent",
  async (_, { rejectWithValue }) => {
    try {
      const { header, sections } = await getPostsContent();
      const { BannerImage, Title } = header.Header;

      return {
        header: {
          bannerImage: BannerImage,
          title: Title,
        },
        sections: { ...sections.Sections },
      };
    } catch (error) {
      return rejectWithValue({
        error: "Ha ocurrido un error.",
      });
    }
  }
);
