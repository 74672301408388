import request from "../request";
import { BASE_USER_URL, BASE_VEHICULE_API_URL } from "../url";

export const getCustomersRequest = async ({
  orderBy = "DESC",
  orderByProperty = "createdAt",
  search = "",
  ...query
}) => {
  return await request({
    url:
      `${BASE_USER_URL}/customers?` +
      new URLSearchParams({
        orderBy,
        orderByProperty,
        search,
        ...query,
      }).toString(),
    method: "GET",
  });
};

export const searchCustomer = async (search) => {
  return await request({
    url: `${BASE_USER_URL}/customers?search=${search}`,
    method: "GET",
  });
};

export const getCustomerAssignmentRequest = async (
  query,
  search = "",
  orderBy = "DESC",
  orderByProperty = query.orderByProperty || "createdAt"
) => {
  const params = {
    orderBy,
    orderByProperty,
    ...query,
    search,
  };

  if (!params.orderBy) {
    params.orderBy = "DESC";
  }

  if (!search) {
    delete params.search;
  }

  if (!params.orderByProperty) {
    params.orderByProperty = orderByProperty;
  }

  return await request({
    url:
      `${BASE_USER_URL}/customers/assignment?` +
      new URLSearchParams(params).toString(),
    method: "GET",
  });
};

export const getCustomerRequest = async ({ id }) => {
  return await request({
    url: `${BASE_USER_URL}/customers/${id}`,
    method: "GET",
  });
};

export const getRelatedCustomersRequest = async () => {
  return await request({
    url: `${BASE_USER_URL}/customers/related`,
    method: "GET",
  });
};

export const getCustomerCount = async () => {
  return await request({
    url: `${BASE_USER_URL}/customers/count`,
    method: "GET",
  });
};

export const setEnabledCustomerRequest = async (customerId) => {
  return await request({
    url: `${BASE_USER_URL}/customers/${customerId}`,
    method: "DELETE",
  });
};

export const createCustomerRequest = async (customer) => {
  return await request({
    url: `${BASE_USER_URL}/customers`,
    method: "POST",
    data: {
      ...customer,
    },
  });
};

export const updateCustomerRequest = async (customerId, data) => {
  return await request({
    url: `${BASE_USER_URL}/customers/${customerId}`,
    method: "PATCH",
    data: {
      ...data,
    },
  });
};

export const bulkDataUploadRequest = async (data, action) => {
  return await request({
    url: `${BASE_USER_URL}/customers/upload/${action}`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      ...data,
    },
  });
};

export const bulkDataDownloadRequest = async (action) => {
  return await request({
    url: `${BASE_USER_URL}/customers/upload/${action}`,
    method: "GET",
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
    },
    responseType: "blob",
  });
};

export const getCustomerChildsRequest = async () => {
  return await request({
    url: `${BASE_USER_URL}/customers/childs`,
    method: "GET",
  });
};

export const unassingExecutive = async (customerId) => {
  return await request({
    url: `${BASE_USER_URL}/customers/unassign/executive/${customerId}`,
    method: "DELETE",
  });
};

export const unassingSupervisor = async (customerId) => {
  return await request({
    url: `${BASE_USER_URL}/customers/unassign/supervisor/${customerId}`,
    method: "DELETE",
  });
};

export const getExpirationDateByCustomer = async (rut) => {
  return request({
    url: `${BASE_VEHICULE_API_URL}/documents/get-expiration-date?customerId=${rut}`,
    method: "GET",
  });
};
